export const styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px', // '1vh' no es recomendado en este contexto
  transform: 'translate(-50%, -50%)',
  width: '45%',
  backgroundColor: '#fff', // Reemplazo de bgcolor
  border: 'none',
  outline: 'none',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)', // Reemplazo de boxShadow
  borderRadius: '8px',
  padding: '16px', // Reemplazo de p: 2
  height: 'fit-content',
  maxHeight: '80vh',
  justifyContent: 'center',
  zIndex: 95,
};
export const stylesLoading = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  display: 'flex',
  minHeight: "auto",
  flexDirection: 'column',
  gap: '1vh',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'transparent',
  border: 'none',
  outline: 'none',
  // minWidth: '25vh',
  // boxShadow: 24,
  borderRadius: '8px',
  p: 2,
  // height: 'auto',
  overflowY: 'scroll',
  maxHeight: '60vh',

  justifyContent: "space-between",
  zIndex: 0,
};

export const stylesModal = {
  zIndex: 999,
  bgcolor: 'transparent',
};


