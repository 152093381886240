import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useQuery, useMutation } from '@apollo/react-hooks';
import MUIDataTable from 'mui-datatables';
import CustomColumnHeader from '../../../TableComponents/CustomColumnHeader';
import CustomOptions from '../../../CustomOptions';
import { TableCell, Typography, Button } from '@material-ui/core';
import { textLabels } from '../../../../translations/components/MUIDataTable';
import {
  GET_CLIENT_LABOR_DATA,
  UPDATE_CLIENT_LABOR_DATA,
  UPDATE_CLIENT_ATTORNEY_LABOR_DATA_CLIENT,
  GET_CLIENT_ACADEMIC_BACKGROUND,
  DELETE_CLIENT_ACADEMIC_BACKGROUND,
} from '@queries/Client';
import CustomCard from '../../../CustomCard';
import Yup from '../../../../utils/yup';
import StepsForm from '../../../Forms/StepsForm';
import { INPUT_VARIANT } from '../../../Forms/StepsForm/fieldsSelector';
import LoadingIndicator from '../../../LoadingIndicator';
import { MENU_ITEMS_ID } from '../../../../containers/ModelsPages/ClientForm/constants';
import { LIST_MONTHLY_INCOME_RANGES } from '../../../Models/MonthlyIncomeRange/queries';
import { rucValidator } from '../../../Validations';
import { LIST_EDUCATION_LEVELS } from '../../../Models/EducationLevel/queries';
import {
  edgeToList,
  errorMessage,
  successMessage,
  getOptions,
} from '../../../../utils/commonFunctions';
import { FORM_OPTIONS } from '../../constants';
import { getIsPeruvianSelector } from '../../../../containers/ModelsPages/ClientForm/selectors';
import CustomModal from '../../../CustomModal';
import AcademicBackgroundForm from '../AcademicBackgroundForm';
import useStyles from '../../PEP/Relative/styles';

function LaborDataClient(props) {
  const { clientID } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [hasRuc, setHasRuc] = useState(false);
  const [showExperience, setShowExperience] = useState(false);
  const isPeruvian = useSelector(getIsPeruvianSelector());
  const [currentAcademicBackground, setCurrentAcademicBackground] = useState(
    null,
  );
  const [showForm, setShowForm] = useState(false);
  const [academicBackgrounds, setAcademicBackgrounds] = useState([]);
  const [client, setClient] = useState(null);
  const classes = useStyles();

  const {
    loading: loadingData,
    error: clientDataError,
    data: clientDataAcademic,
  } = useQuery(GET_CLIENT_ACADEMIC_BACKGROUND, {
    variables: { id: clientID },
  });

  const deleteAcademicBackgroundMutation = useMutation(
    DELETE_CLIENT_ACADEMIC_BACKGROUND,
    getOptions({
      mutationName: 'createRelative',
      modelName: 'relative',
      message: 'Se guardó datos del pariente con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_ACADEMIC_BACKGROUND, variables: { id: clientID } },
      ],
      onCompleted: data => {
        const { errors } = data.deleteAcademicBackground;
        if (errors) {
          errorMessage(errors, enqueueSnackbar);
        }
      },
    }),
  );

  useEffect(() => {
    if (clientDataAcademic) {
      setClient(clientDataAcademic.client);
      const tmpRelatives = edgeToList(
        clientDataAcademic.client,
        'academicBackgrounds',
      );
      setAcademicBackgrounds(tmpRelatives);
      if (currentAcademicBackground) {
        let tmpRelative = tmpRelatives.find(
          v => v.id === currentAcademicBackground.id,
        );
        setCurrentAcademicBackground(tmpRelative);
      }
    }
  }, [clientDataAcademic, currentAcademicBackground]);

  const { loading, error: clientError, data: clientData } = useQuery(
    GET_CLIENT_LABOR_DATA,
    {
      variables: { id: clientID },
    },
  );

  const updateClient = useMutation(UPDATE_CLIENT_LABOR_DATA, {
    onError(error) {
      const { graphQLErrors } = error;
      errorMessage(graphQLErrors, enqueueSnackbar);
    },
    onCompleted(data) {
      const { client, errors } = data.updateClient;
      if (client) {
        successMessage('Se guardó datos laborales.', enqueueSnackbar);
      } else {
        errorMessage(errors, enqueueSnackbar);
      }
    },
  });

  const partialUpdateClient = useMutation(
    UPDATE_CLIENT_ATTORNEY_LABOR_DATA_CLIENT,
    {
      onError(error) {
        const { graphQLErrors } = error;
        errorMessage(graphQLErrors, enqueueSnackbar);
      },
      onCompleted(data) {
        const { client, errors } = data.updateClient;
        if (client) {
          successMessage(
            'Se guardó datos laborales parcialmente.',
            enqueueSnackbar,
          );
        } else {
          errorMessage(errors, enqueueSnackbar);
        }
      },
    },
  );

  // SELECT QUERIES
  const {
    loading: monthlyIncomeRangeLoading,
    error: monthlyIncomeRangeError,
    data: monthlyIncomeRangeData,
  } = useQuery(LIST_MONTHLY_INCOME_RANGES);
  const {
    error: educationLevelsError,
    data: educationLevelsData,
    loading: educationLevelsLoading,
  } = useQuery(LIST_EDUCATION_LEVELS);
  if (monthlyIncomeRangeError) {
    console.error(
      'Labor data - list monthly income ranges',
      monthlyIncomeRangeError,
    );
  }
  if (educationLevelsError) {
    console.error('Labor data - list education levels', educationLevelsError);
  }

  useEffect(() => {
    if (clientData && clientData.client) {
      setHasRuc(!!clientData.client.ruc);
      setShowExperience(clientData.client.hasWorkingExperience.toString());
    }
  }, [clientData]);

  function handleHasRucChange(event) {
    event.preventDefault();
    if (event.target.value) {
      setHasRuc(event.target.value === 'true');
    }
  }

  function handleShowExperience(event) {
    event.preventDefault();
    if (event.target.value) {
      setShowExperience(event.target.value);
    }
  }

  function validationFunction(values) {
    const errors = {};
    if (values.hasRuc === 'true') {
      rucValidator(values.ruc, errors);
    }
    return errors;
  }

  const validationSchema = Yup.object().shape({
    occupation: Yup.string().required(),
    workplace: Yup.string().when({
      is: () => showExperience === 'true',
      then: Yup.string().required(),
    }),
    role: Yup.string().when({
      is: () => showExperience === 'true',
      then: Yup.string().required(),
    }),
    taxNumber: Yup.number().when({
      is: () => !isPeruvian,
      then: Yup.number().required(),
    }),
    grossMonthlySalary: Yup.number()
      .min(1)
      .required(),
  });

  let startValues = {};

  if (loading) return <LoadingIndicator />;
  if (clientError) {
    console.error('get client', clientError);
  }
  if (clientData) {
    const data = clientData.client;
    let educationLevelId = data.educationLevel;
    if (data.educationLevel && data.educationLevel.id) {
      educationLevelId = data.educationLevel.id;
    }
    data.educationLevel = educationLevelId;
    data.hasRuc = (!!data.ruc).toString();
    data.hasWorkingExperience = data.hasWorkingExperience.toString();
    startValues = { ...data };
  } else {
    startValues = {
      occupation: '',
      workplace: '',
      role: '',
      startDate: '',
      endDate: '',
      averageMonthIncome: '',
      educationLevel: '',
      ruc: '',
      taxNumber: '',
    };
  }

  const data = [
    {
      isField: false,
      section: 'RUC',
      fields: [
        {
          name: 'hasRuc',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.radio,
          data: FORM_OPTIONS.yesNo,
          onChange: handleHasRucChange,
        },
      ],
    },
    {
      isField: true,
      label: '* Profesión',
      name: 'profession',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.onlyText,
    },
    {
      isField: true,
      label: 'Nº Colegio Profesional',
      name: 'professionalNumber',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.text,
    },
    {
      isField: true,
      label: '* Cargo Actual',
      name: 'occupation',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.onlyText,
    },
    {
      isField: false,
      section: 'Fuentes de Ingreso Mensuales',
      fields: [
        {
          label: '* Remuneración bruta mensual (planilla del empleador)',
          name: 'grossMonthlySalary',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.number,
        },
        {
          label:
            'Otros ingresos por ejercicio individual de profesión, oficio u otra labor',
          name: 'otherGrossMonthlySalary',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.number,
        },
        {
          label: 'Otros ingresos mensuales',
          name: 'otherMonthlyIncome',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.number,
        },
      ],
    },
    {
      isField: false,
      section: 'Experiencia Laboral',
      fields: [
        {
          name: 'hasWorkingExperience',
          gridMD: 12,
          inputVariant: INPUT_VARIANT.radio,
          data: FORM_OPTIONS.yesNo,
          onChange: handleShowExperience,
        },
      ],
    },
    // {
    //   isField: false,
    //   section: 'Ingreso promedio mensual',
    //   fields: [
    //     {
    //       label: '* Ingreso promedio mensual',
    //       name: 'averageMonthIncome',
    //       inputVariant: INPUT_VARIANT.select,
    //       gridMD: 12,
    //       data: monthlyIncomeRangeEdgeToList(
    //         monthlyIncomeRangeData,
    //         'listMonthlyIncomeRanges'
    //       ),
    //       mapData: { value: 'id', label: 'text' },
    //     },
    //     {
    //       name: 'averageIncomeText',
    //       text: monthlyIncomeRangeParagraph(
    //         monthlyIncomeRangeData,
    //         'listMonthlyIncomeRanges'
    //       ),
    //       gridMD: 12,
    //       inputVariant: INPUT_VARIANT.paragraph,
    //     },
    //   ],
    // },
    // {
    //   isField: true,
    //   label: '* Nivel de Instrucción',
    //   name: 'educationLevel',
    //   gridMD: 12,
    //   inputVariant: INPUT_VARIANT.select,
    //   data: edgeToList(educationLevelsData, 'listEducationLevels'),
    //   mapData: { value: 'id', label: 'name' },
    // },
  ];

  if (hasRuc) {
    data[0].fields.push({
      isField: true,
      label: '* Registro Único de Contribuyente',
      name: 'ruc',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.text,
    });
  }

  if (showExperience === 'true') {
    data[5].fields.push(
      {
        label: '1º Nombre de la empresa o institución',
        name: 'workplace',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.onlyText,
      },
      {
        label: '* Cargo',
        name: 'role',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.onlyText,
      },
      {
        isField: true,
        label: '* Fecha de Inicio',
        name: 'startDate',
        dateT: 'year',
        gridMD: 6,
        inputVariant: INPUT_VARIANT.date,
      },
      {
        isField: true,
        label: '* Fecha de Término',
        name: 'endDate',
        dateT: 'year',
        gridMD: 6,
        inputVariant: INPUT_VARIANT.date,
      },
      {
        label: '* Motivo de cese',
        name: 'reasonToLeave',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.onlyText,
      },
      {},
      {},
      {},
      {
        label: '2º Nombre de la empresa o institución',
        name: 'workplaceSecond',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.onlyText,
      },
      {
        label: '* Cargo',
        name: 'roleSecond',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.onlyText,
      },
      {
        isField: true,
        label: '* Fecha de Inicio',
        name: 'startDateSecond',
        dateT: 'year',
        gridMD: 6,
        inputVariant: INPUT_VARIANT.date,
      },
      {
        isField: true,
        label: '* Fecha de Término',
        name: 'endDateSecond',
        dateT: 'year',
        gridMD: 6,
        inputVariant: INPUT_VARIANT.date,
      },
      {
        label: '* Motivo de cese',
        name: 'reasonToLeaveSecond',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.onlyText,
      },
      {},
      {},
      {},
      {
        label: '3º Nombre de la empresa o institución',
        name: 'workplaceThird',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.onlyText,
      },
      {
        label: '* Cargo',
        name: 'roleThird',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.onlyText,
      },
      {
        isField: true,
        label: '* Fecha de Inicio',
        name: 'startDateThird',
        dateT: 'year',
        gridMD: 6,
        inputVariant: INPUT_VARIANT.date,
      },
      {
        isField: true,
        label: '* Fecha de Término',
        name: 'endDateThird',
        dateT: 'year',
        gridMD: 6,
        inputVariant: INPUT_VARIANT.date,
      },
      {
        label: '* Motivo de cese',
        name: 'reasonToLeaveThird',
        gridMD: 12,
        inputVariant: INPUT_VARIANT.onlyText,
      },
    );
  }

  if (!isPeruvian) {
    data.push({
      isField: true,
      label: '* Número de identificación tributaria ',
      name: 'taxNumber',
      gridMD: 12,
      inputVariant: INPUT_VARIANT.number,
    });
  }

  function formatBeforeSubmit(values) {
    const {
      years,
      months,
      yearsSecond,
      monthsSecond,
      yearsThird,
      monthsThird,
      user,
      hasRuc: hasRucData,
      ruc,
      ...newValues
    } = values;
    newValues.ruc = hasRucData === 'true' ? ruc : '';
    newValues.hasWorkingExperience = showExperience === 'true' ? true : false;

    return newValues;
  }

  const columns = [
    {
      name: 'degree',
      label: 'Grado Obtenido',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={columnMeta.index}
              key={columnMeta.index}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
    {
      name: 'educationalInstitution',
      label: 'Centro de estudios',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={columnMeta.index}
              key={columnMeta.index}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
      },
    },
  ];
  columns.push({
    name: 'id',
    label: 'Opciones',
    options: {
      sort: false,
      customBodyRender: value => {
        const id = value;
        return (
          <CustomOptions
            id={id}
            model="Formación Académica"
            options={['cedit', 'odelete']}
            handleDelete={() =>
              deleteAcademicBackgroundMutation[0]({ variables: { id } })
            }
            handleEdit={() => {
              const academicBackground = academicBackgrounds.filter(
                v => id === v.id,
              )[0];
              setShowForm(true);
              setCurrentAcademicBackground(academicBackground);
            }}
          />
        );
      },
      customHeadRender: columnMeta => {
        return (
          <TableCell key={columnMeta.index} style={{ borderBottom: 'none' }}>
            <Typography
              variant="subtitle1"
              align="left"
              className={classes.tableTitle}
            >
              {columnMeta.label}
            </Typography>
          </TableCell>
        );
      },
    },
  });

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    textLabels,
    customToolbar: () => {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setCurrentAcademicBackground(null);
            setShowForm(true);
          }}
        >
          Añadir Formación Académica
        </Button>
      );
    },
    onRowClick: rowData => {
      setCurrentAcademicBackground(
        academicBackgrounds.find(v => rowData[2].props.id === v.id),
      );
      setShowForm(true);
    },
  };

  const closeForm = () => {
    setCurrentAcademicBackground(null);
    setShowForm(false);
  };

  return loading ||
    !client ||
    educationLevelsLoading ||
    monthlyIncomeRangeLoading ? (
    <LoadingIndicator />
  ) : (
    <>
      <StepsForm
        id={clientID}
        mainClientID={clientID}
        initialValues={startValues}
        validateFunction={validationFunction}
        validateSchema={validationSchema}
        data={data}
        title="Datos Laborales"
        nextForm={MENU_ITEMS_ID.ClientSafiRelation}
        updateMutation={updateClient}
        partialUpdateMutation={partialUpdateClient}
        formatBeforeSubmit={formatBeforeSubmit}
        subtitle="Formación Académica"
        dataInsideCard={academicBackgrounds}
        columns={columns}
        options={options}
        emergencyContact={false}
      />
      <CustomModal
        title="Formación Académica"
        showModal={showForm}
        onClosePress={() => closeForm()}
      >
        <AcademicBackgroundForm
          clientId={client.id}
          academicBackgroundData={
            currentAcademicBackground ? currentAcademicBackground : {}
          }
          closeForm={() => closeForm()}
          setCurrentAcademicBackground={id =>
            setCurrentAcademicBackground(
              academicBackgrounds.filter(v => v.id === id)[0],
            )
          }
        />
      </CustomModal>
    </>
  );
}

LaborDataClient.propTypes = {
  clientID: PropTypes.string.isRequired,
};

export default LaborDataClient;
