/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';

// Material Resources
import { Button, Tooltip, IconButton } from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PieChartIcon from '@material-ui/icons/PieChart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

// Others
import PropTypes from 'prop-types';
import { useRouteMatch, useHistory } from 'react-router-dom';

// Styles & Images
import useStyles from './styles';
import DeleteModal from '../DeleteModal';

const mockOptions = [
  'ocustom',
  'oadditional',
  'oconfirm',
  'odeny',
  'oedit',
  'cedit',
  'odelete',
  'oattached',
  'oincreases',
  'oinvestment',
];

function CustomOptions(props) {
  const {
    id,
    model,
    options,
    handleDelete,
    handleEdit,
    customOption,
    additionalOption,
    secondAdditionalOption,
    thirdAdditionalOption,
    fourthAdditionalOption,
    handleSubmit,
    handleAttach,
    extraData,
    extraFunctions,
    separatedOptions,
    titleAttach,
    handleConfirmOption,
    handleDenyOption,
    disabledButtons,
  } = props;
  const [customOptions, setCustomOptions] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const { path } = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();
  const [disableConfirm, setDisableConfirmButton] = useState(false);

  const handleDeleteOpen = e => {
    setOpenDelete(true);
  };

  const handleDeleteCancel = () => {
    setOpenDelete(false);
  };
  const handleDeleteOK = () => {
    handleDelete();
    setOpenDelete(false);
  };

  const handleEditOption = () => {
    history.push(`${path}/${id}`);
  };

  useEffect(() => {
    const AllOptions = {
      oconfirm: (
        <Tooltip title="Aprobar" placement="top" arrow>
          <IconButton
            variant="contained"
            color="secondary"
            size="small"
            className={classes.optionButtons}
            onClick={() => {
              setDisableConfirmButton(true);
              handleConfirmOption();
            }}
            disabled={disabledButtons || disableConfirm}
          >
            <CheckOutlinedIcon className={classes.optionIcon} />
          </IconButton>
        </Tooltip>
      ),
      odeny: (
        <Tooltip title="Desaprobar" placement="top" arrow>
          <IconButton
            variant="contained"
            color="secondary"
            size="small"
            className={classes.optionButtons}
            onClick={handleDenyOption}
            disabled={disabledButtons}
          >
            <CloseOutlinedIcon className={classes.optionIcon} />
          </IconButton>
        </Tooltip>
      ),
      oedit: (
        <Tooltip title="Editar" placement="top" arrow>
          <IconButton
            variant="contained"
            color="secondary"
            size="small"
            className={classes.optionButtons}
            onClick={handleEditOption}
          >
            <EditOutlinedIcon className={classes.optionIcon} />
          </IconButton>
        </Tooltip>
      ),
      oedit_signature: (
        <Tooltip title="Editar" placement="top" arrow>
          <IconButton
            variant="contained"
            color="secondary"
            size="small"
            className={classes.optionButtons}
            onClick={handleSubmit}
          >
            <EditOutlinedIcon className={classes.optionIcon} />
          </IconButton>
        </Tooltip>
      ),
      cedit: (
        <Tooltip title="Editar" placement="top" arrow>
          <IconButton
            variant="contained"
            color="secondary"
            size="small"
            className={classes.optionButtons}
            onClick={handleEdit}
          >
            <EditOutlinedIcon className={classes.optionIcon} />
          </IconButton>
        </Tooltip>
      ),
      odelete: (
        <Tooltip title="Eliminar" placement="top" arrow>
          <IconButton
            variant="contained"
            color="secondary"
            className={classes.optionButtons}
            onClick={handleDeleteOpen}
          >
            <DeleteOutlineOutlinedIcon className={classes.optionIcon} />
          </IconButton>
        </Tooltip>
      ),
      oenable: (
        <Tooltip
          title={extraData.oenable ? 'Desactivar' : 'Activar'}
          placement="top"
          arrow
        >
          <IconButton
            variant="contained"
            className={classes.optionButtons}
            onClick={extraFunctions.oenable && extraFunctions.oenable}
          >
            <CheckCircleOutlineOutlinedIcon
              className={`${classes.optionIcon} ${
                extraData.oenable ? classes.iorange : classes.igray
              }`}
            />
          </IconButton>
        </Tooltip>
      ),
      oattached: (
        <Tooltip title={titleAttach} placement="top" arrow>
          <Button
            variant="contained"
            size="small"
            className={`${classes.optionButtons} ${classes.yellow}`}
            onClick={handleAttach}
          >
            <FileCopyIcon />
          </Button>
        </Tooltip>
      ),
      oincreases: (
        <Tooltip title="Aumentos" placement="top" arrow>
          <Button
            variant="contained"
            size="small"
            className={`${classes.optionButtons} ${classes.green}`}
          >
            <TrendingUpIcon />
          </Button>
        </Tooltip>
      ),
      oinvestment: (
        <Tooltip title="Inversiones" placement="top" arrow>
          <Button
            variant="contained"
            size="small"
            className={`${classes.optionButtons} ${classes.blue}`}
          >
            <PieChartIcon />
          </Button>
        </Tooltip>
      ),
      ostate: (
        <Tooltip title="Estado" placement="top" arrow>
          <Button
            variant="contained"
            size="small"
            className={`${classes.optionButtons} ${classes.orange}`}
          >
            <CheckCircleIcon />
          </Button>
        </Tooltip>
      ),
      ocustom: customOption,
      oadditional: additionalOption,
      osecondadditional: secondAdditionalOption,
      othirdadditional: thirdAdditionalOption,
      ofourthadditional: fourthAdditionalOption,
    };
    let getOptions;
    if (!options) {
      getOptions = ['odelete'];
    } else {
      getOptions = options;
    }
    const currentOptions = getOptions.map(opt => {
      return AllOptions[opt];
    });
    setCustomOptions(currentOptions);
  }, [options, extraData]);

  return (
    <div onClick={e => e.stopPropagation()}>
      <div className={classes.iconsContainer}>
        {customOptions.map((opt, idx) => (
          <span className={classes.singleOption} key={mockOptions[idx]}>
            {opt}
          </span>
        ))}
        {separatedOptions && (
          <>
            <div className={classes.separator} />
            {separatedOptions}
          </>
        )}
      </div>
      <DeleteModal
        model={model}
        open={openDelete}
        handleClose={handleDeleteCancel}
        handleOK={handleDeleteOK}
      />
    </div>
  );
}

CustomOptions.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleDelete: PropTypes.func,
  handleAttach: PropTypes.func,
  handleEdit: PropTypes.func,
  toggleState: PropTypes.func,
  customOption: PropTypes.node,
  additionalOption: PropTypes.node,
  extraData: PropTypes.object,
  extraFunctions: PropTypes.object,
  separatedOptions: PropTypes.node,
};

CustomOptions.defaultProps = {
  handleDelete: null,
  handleAttach: null,
  handleEdit: null,
  toggleState: null,
  customOption: null,
  additionalOption: null,
  extraData: {},
  extraFunctions: {},
  separatedOptions: null,
  titleAttach: 'Adjuntos',
};

export default CustomOptions;
