import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import MUIDataTable from 'mui-datatables';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  IconButton,
  Input,
  Button,
  Tooltip,
  Typography,
  TableCell,
} from '@material-ui/core';

import CustomOptions from '../../CustomOptions';
import LoadingIndicator from '../../LoadingIndicator';
import {
  LIST_INTERESTED_INVESTORS,
  DELETE_USER_INTERESTED_INVESTORS,
  SEND_CREDENTIALS,
} from './queries';
import {
  generateColumn,
  getOptions,
  errorMessage,
  edgeToList,
} from '../../../utils/commonFunctions';

import CustomColumnHeader from '../../TableComponents/CustomColumnHeader';
import { textLabels } from '../../../translations/components/MUIDataTable';
import CustomModal from 'src/components/CustomModal';

// styles and icons
import useStyles from './styles';
import SendIcon from '@material-ui/icons/Send';
import RefreshIcon from '@material-ui/icons/Refresh';
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import LoadingWithMessage from 'src/components/LoadingWithMessage';

import { REACT_APP_SERVER_LINK } from 'src/utils/constants';

function InterestedInvestor(props) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [interestedInvestorList, setInterestedInvestorList] = useState([]);
  const [photoDocument, setPhotoDocument] = useState({ front: '', back: '' });
  const [showModal, setShowModal] = useState(false);
  const [pageInfo, setPageInfo] = useState('');
  const [totalCount, setTotalCount] = useState('');
  const [listLength, setListLength] = useState(0);
  const [pageLength, setPageLength] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [searchTextInput, setSearchTextInput] = useState('');

  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [loadingResult, setLoadingResult] = useState('');

  const downloadFromLink = link => {
    const a = document.createElement('a');
    a.style.display = 'none';
    document.body.appendChild(a);
    a.href = link;
    a.setAttribute('download', true);
    a.setAttribute('target', '_blank');
    a.click();
    document.body.removeChild(a);
  };

  const [deleteInvestor] = useMutation(
    DELETE_USER_INTERESTED_INVESTORS,
    getOptions({
      mutationName: 'deleteUser',
      modelName: 'user',
      message: 'Inversionista interesado eliminado con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        {
          query: LIST_INTERESTED_INVESTORS,
          variables: {
            search: searchText ? searchText : null,
            first: 10,
            afterCursor: null,
            last: null,
            beforeCursor: null,
          },
        },
      ],
    }),
  );

  const [sendCredentials] = useMutation(
    SEND_CREDENTIALS,
    getOptions({
      mutationName: 'sendCredentials',
      modelName: 'client',
      message: 'Email enviado con éxito.',
      enqueueSnackbar,
      completeCallback: (data, errors) => {
        setLoadingState(false);
        setLoadingResult(true);
      },
      errorCallback: error => {
        setLoadingState(false);
        setLoadingResult(false);
      },
      refetchQueries: [
        {
          query: LIST_INTERESTED_INVESTORS,
          variables: {
            search: searchText ? searchText : null,
            first: 10,
            afterCursor: null,
            last: null,
            beforeCursor: null,
          },
        },
      ],
    }),
  );

  const {
    loading: listLoading,
    error: listError,
    data: listData,
    fetchMore,
    refetch,
  } = useQuery(LIST_INTERESTED_INVESTORS, {
    variables: {
      search: searchText ? searchText : null,
      first: 10,
      afterCursor: null,
      last: null,
      beforeCursor: null,
    },
  });

  if (listError) {
    console.error('Interested Investor - list investors', listError);
  }

  function edgeToListInvestors(data, listName) {
    if (data) {
      return data[listName].edges.map(element => {
        let complete = { ...element.node };
        const user = { ...element.node.user };
        user.userID = user.id;
        delete user.id;
        delete complete.user;
        complete = { ...complete, ...user };
        return complete;
      });
    }
    return [];
  }

  function getUsersByClients(clients) {
    setListLength(clients.length);
    clients.filter(element => !element.isEmailAutogenerated);
    return clients.map(element => {
      let save = { ...element };
      save = {
        ...element.user,
        ...{ core2Uuid: element.core2Uuid },
        ...{ promoter: element.promoter },
        ...{ coreConsultant: element.coreConsultant },
      };
      save.optionValue = {
        id: element.id,
        isRiskConsulted: element.isRiskConsulted,
        isActive: element.user?.isActive,
        urlRiskReport: element.urlRiskReport,
        photoDocumentFront: element.photoDocumentFront,
        photoDocumentBack: element.photoDocumentBack,
        photoDocumentsUrl: element.photoDocumentsUrl,
      };
      return save;
    });
  }

  useEffect(() => {
    if (listData) {
      setTotalCount(listData.listClients.totalCount);
      setPageInfo(listData.listClients.pageInfo);
      setInterestedInvestorList(
        getUsersByClients(edgeToList(listData, 'listClients')),
      );
    }
  }, [listData]);

  const columns = [
    generateColumn('Nombres', 'firstName'),
    generateColumn('Apellidos Paterno', 'lastName'),
    generateColumn('Apellidos Materno', 'maternalSurname'),
    generateColumn('Email', 'email'),
    {
      name: 'promoter',
      label: 'Promotor',
      options: {
        sort: false,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={columnMeta.index}
              key={columnMeta.index}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
        customBodyRender: value => {
          return (
            <Typography variant="subtitle1" align="left">
              {value && `${value.firstName} ${value.lastName}`}
            </Typography>
          );
        },
      },
    },
    {
      name: 'coreConsultant',
      label: 'Asesor',
      options: {
        sort: false,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={columnMeta.index}
              key={columnMeta.index}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
        customBodyRender: value => {
          return (
            <Typography variant="subtitle1" align="left">
              {value && `${value.firstName} ${value.lastName}`}
            </Typography>
          );
        },
      },
    },
    {
      name: 'urlRiskReport',
      label: 'Validación LAFT',
      options: {
        sort: false,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={columnMeta.index}
              key={columnMeta.index}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
        customBodyRender: value => {
          return (
            <Tooltip title={'Reporte de Riesgo'} placement="top" arrow>
              <IconButton
                variant="contained"
                color="secondary"
                size="small"
                className={classes.optionButtons}
                onClick={() => window.open(value, '_blank')}
              >
                <CheckCircleIcon
                  className={`${classes.optionIcon} ${classes.orangeIcon}`}
                />
              </IconButton>
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'isActive',
      label: 'Activo',
      options: {
        sort: true,
        customHeadRender: (columnMeta, updateDirection) => {
          return (
            <CustomColumnHeader
              id={6}
              key={6}
              columnMeta={columnMeta}
              updateDirection={updateDirection}
            />
          );
        },
        customBodyRender: value => (value ? 'Sí' : 'No'),
      },
    },
    {
      name: 'optionValue',
      label: 'Opciones',
      options: {
        sort: false,
        customBodyRender: value => {
          const {
            id,
            userID,
            isRiskConsulted,
            isActive,
            photoDocumentFront,
            photoDocumentBack,
            photoDocumentsUrl,
          } = value;
          return (
            <CustomOptions
              id={id}
              model="Banco"
              // options={[isActive && 'oedit', 'odelete', 'ocustom']}
              options={['oedit', 'ocustom', 'osecondadditional', 'oadditional']}
              handleEdit={() => {
                history.push(`${history.location.pathname}/${userID}`);
              }}
              handleDelete={() => {
                deleteInvestor({ variables: { id: userID } });
              }}
              customOption={
                <span>
                  <Tooltip
                    title={
                      isRiskConsulted
                        ? 'Enviar ficha cliente'
                        : 'Aún no es posible enviar la ficha cliente'
                    }
                    placement="top"
                    arrow
                  >
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.optionButtons}
                      onClick={
                        isRiskConsulted
                          ? () => {
                              sendCredentials({ variables: { id } });
                              setLoadingState(true);
                              setLoadingModal(true);
                            }
                          : () => {}
                      }
                    >
                      {isRiskConsulted ? (
                        <SendIcon className={classes.optionIcon} />
                      ) : (
                        <CancelScheduleSendIcon
                          className={classes.optionIcon}
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                </span>
              }
              secondAdditionalOption={
                <span>
                  <Tooltip arrow title="Ver documento" placement="top">
                    <IconButton
                      color="secondary"
                      size="small"
                      className={classes.optionButtons}
                      onClick={() => {
                        setPhotoDocument({
                          front: photoDocumentFront,
                          back: photoDocumentBack,
                        });
                        setShowModal(true);
                      }}
                      disabled={!photoDocumentFront}
                    >
                      <RecentActorsOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </span>
              }
              additionalOption={
                <span>
                  <Tooltip arrow title="Descargar documentos" placement="top">
                    <IconButton
                      color="secondary"
                      size="small"
                      className={classes.optionButtons}
                      onClick={() =>
                        downloadFromLink(
                          `${REACT_APP_SERVER_LINK}${photoDocumentsUrl}`,
                        )
                      }
                      disabled={!photoDocumentFront}
                    >
                      <CloudDownloadIcon />
                    </IconButton>
                  </Tooltip>
                </span>
              }
            />
          );
        },
        customHeadRender: columnMeta => {
          return (
            <TableCell key={7} style={{ borderBottom: 'none' }}>
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    pagination: false,
    textLabels,
    customToolbar: () => {
      return (
        <>
          <Tooltip title="Nuevo Inversionista Interesado">
            <IconButton
              color="secondary"
              onClick={() => history.push(`${history.location.pathname}/crear`)}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Refrescar">
            <IconButton
              color="secondary"
              onClick={e => {
                setPageLength(10);
                setSearchText('');
                refetch();
              }}
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </>
      );
    },
    customSearchRender: () => {
      return (
        <div>
          <Input
            autoFocus
            className={classes.searchInput}
            variant="outlined"
            type="text"
            onChange={e => {
              setSearchTextInput(e.target.value);
            }}
          ></Input>
          <Button
            variant="contained"
            className={classes.searchButton}
            onClick={() => {
              setSearchText(searchTextInput);
            }}
          >
            {' '}
            Buscar{' '}
          </Button>
        </div>
      );
    },
    onSearchClose: () => {
      setPageLength(10);
      setSearchText('');
      refetch();
    },
  };

  return (
    <>
      <MUIDataTable
        title={
          searchText.length > 0
            ? 'Resultados: ' + searchText
            : 'Inversionistas Interesados'
        }
        data={interestedInvestorList}
        columns={columns}
        options={options}
      />
      <div className={classes.buttonContainer}>
        {listLength < pageLength && pageLength > totalCount ? (
          <div className={classes.paginationText}>
            {totalCount} de {totalCount}
          </div>
        ) : (
          <div className={classes.paginationText}>
            {pageLength} de {totalCount}
          </div>
        )}
        {pageLength - listLength > 0 && listLength != totalCount ? (
          <div
            className={classes.paginationButton}
            onClick={() => {
              if (pageLength > totalCount) setPageLength(pageLength - 10);
              else setPageLength(pageLength - listLength);
              if (pageInfo?.startCursor) {
                fetchMore({
                  variables: {
                    beforeCursor: pageInfo.startCursor,
                    last: 10,
                    afterCursor: null,
                    first: null,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return fetchMoreResult;
                  },
                });
              }
            }}
          >
            {' '}
            Anterior{' '}
          </div>
        ) : (
          <span></span>
        )}
        {pageLength <= totalCount ? (
          <div
            className={classes.paginationButton}
            onClick={() => {
              setPageLength(pageLength + listLength);
              fetchMore({
                variables: {
                  afterCursor: pageInfo?.endCursor,
                  first: 10,
                  last: null,
                  beforeCursor: null,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  return fetchMoreResult;
                },
              });
            }}
          >
            {' '}
            Siguiente{' '}
          </div>
        ) : (
          <span></span>
        )}
      </div>
      {photoDocument.front && (
        <CustomModal
          maxWidth="lg"
          showModal={showModal}
          title="Documento"
          onClosePress={() => {
            setShowModal(false);
          }}
        >
          <div className={classes.photoDocuments}>
            <img
              alt="front"
              src={`${REACT_APP_SERVER_LINK}/media/${photoDocument.front}`}
            />

            <img
              alt="back"
              src={`${REACT_APP_SERVER_LINK}/media/${photoDocument.back}`}
            />
          </div>
        </CustomModal>
      )}
    </>
  );
}

export default InterestedInvestor;
