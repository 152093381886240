import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// GraphQl
import { useQuery, useMutation } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import SimpleForm, {
  INPUT_VARIANT_SIMPLE_FORM,
} from '../../../../Forms/SimpleForm';
import { INPUT_VARIANT } from '../../../../Forms/StepsForm/fieldsSelector';
import {
  UPDATE_USER,
  CREATE_USER,
  DELETE_USER,
  LIST_CLIENT_USERS,
  LIST_PROMOTERS,
  UDPATE_CLIENT_INTERESTED_INVESTORS,
  LIST_CANAL_ACTUAL,
  LIST_CANAL_ORIGEN,
} from '../../queries';
import { LIST_DOCUMENT_TYPES } from '../../../DocumentType/queries';
import { edgeToList, getOptions } from '../../../../../utils/commonFunctions';
import { LIST_PROMOTER_COMPANIES } from '../../../PromoterComponent/queries';

import { validationFunctionClient, validationSchemaClient } from './validation';

const userTypes = [
  { token: 'PN', id: 'PERSONA_NATURAL', name: 'Persona natural' },
  { token: 'PJ', id: 'PERSONA_JURIDICA', name: 'Persona jurídica' },
  { token: 'AU', id: 'PERSONA_AUTONOMA', name: 'Persona autónoma' },
];

const startValues = {
  firstName: '',
  lastName: '',
  maternalSurname: '',
  isStaff: false,
  email: '',
  documentType: '',
  documentNumber: '',
  userType: '',
  promoter: '',
  promoterCompany: '',
  coreConsultant: '',
  canalActual: '',
  canalOrigen: '',
  enviarComunicacion: '',
  nombreComunicacion: '',
  nombreCanalActual: '',
  nombreCanalOrigen: '',
  correoIntermediario: '',
};

function ClientForm(props) {
  const { client, id, isEdit } = props;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [finalClient, setFinalClient] = useState({});
  const [clientData, setClientData] = useState(null);
  const [promoters, setPromoters] = useState([]);
  const [corePromoters, setCorePromoters] = useState([]);
  const [saveClientData, setSaveClientData] = useState({});
  const [isOnlyCore, setIsOnlyCore] = useState('');

  const {
    loading: promotersLoading,
    error: promotersError,
    data: promotersData,
  } = useQuery(LIST_PROMOTERS);

  const {
    loading: promoterCompaniesLoading,
    error: promoterCompaniesError,
    data: promoterCompaniesData,
  } = useQuery(LIST_PROMOTER_COMPANIES);

  function promoterEdgeToList(promotersList) {
    if (promotersList) {
      return promotersList.edges.map(element => {
        const newNode = {
          id: element.node.id,
          fullName: `${element.node.firstName} ${element.node.lastName}`,
          enabled: element.node.enabled,
        };
        return newNode;
      });
    }
    return [];
  }

  function promotersPerCompany({ target }) {
    if (promoterCompaniesData) {
      const company = edgeToList(
        promoterCompaniesData,
        'listPromoterCompany',
      ).find(companies => companies.id === target.value);
      if (company?.businessName?.split(' ')[0] == 'Core') {
        setIsOnlyCore(true);
      } else setIsOnlyCore(false);
      let filteredPromoters = promoterEdgeToList(company.promoters).filter(
        p => p.enabled == true,
      );
      setPromoters(filteredPromoters);
    }
  }

  useEffect(() => {
    if (client) {
      const finalClient = client?.clientSet?.edges.filter(
        c => c.node.state == 'client' && !c.node.isMain,
      )[0].node;
      setFinalClient(finalClient);
    }
    if (promotersData) {
      const promoters = edgeToList(promotersData, 'listPromoter')
        .filter(promoter => promoter.isCoreConsultant)
        .map(element => {
          const newNode = {
            id: element.id,
            fullName: `${element.firstName} ${element.lastName}`,
            enabled: element.enabled,
            isCoreConsultant: element.isCoreConsultant,
          };
          return newNode;
        });
      setCorePromoters(promoters);
      const promotersSabbi = edgeToList(promotersData, 'listPromoter')
        .filter(promoter => !promoter.isCoreConsultant)
        .map(element => {
          const newNode = {
            id: element.id,
            fullName: `${element.firstName} ${element.lastName}`,
            enabled: element.enabled,
            isCoreConsultant: element.isCoreConsultant,
          };
          return newNode;
        });
      setPromoters(promotersSabbi);
    }
  }, [client, promotersData]);

  useEffect(() => {
    if (finalClient && client) {
      let complete = {
        ...finalClient,
        ...{ user: client },
      };
      if (complete?.promoterCompany) {
        if (
          complete?.promoterCompany == 'UHJvbW90ZXJDb21wYW55VHlwZToy' ||
          complete?.promoterCompany?.id == 'UHJvbW90ZXJDb21wYW55VHlwZToy'
        ) {
          setIsOnlyCore(true);
          complete.promoter = '';
        } else setIsOnlyCore(false);
        complete.promoterCompany = complete.promoterCompany.id
          ? complete.promoterCompany.id
          : complete.promoterCompany;
      }
      if (complete.coreConsultant) {
        complete.coreConsultant = complete.coreConsultant.id
          ? complete.coreConsultant.id
          : complete.coreConsultant;
      }
      if (complete.promoter) {
        complete.promoter = complete.promoter.id
          ? complete.promoter.id
          : complete.promoter;
        if (promoterCompaniesData) {
          const company = edgeToList(
            promoterCompaniesData,
            'listPromoterCompany',
          ).find(e => e.id === complete.promoterCompany);
          const promotersList = promoterEdgeToList(company.promoters).filter(
            p => p.enabled == true,
          );
          setPromoters(promotersList);
        }
      }
      if (complete?.user) {
        complete.firstName = complete.user.firstName;
        complete.lastName = complete.user.lastName;
        complete.maternalSurname = complete.user.maternalSurname;
        complete.email = complete.user.email;
        complete.documentNumber = complete.user.documentNumber;
      }
      if (complete?.user?.documentType && complete?.user?.documentType !== '') {
        complete.documentType = complete.user.documentType
          ? complete.user.documentType.id
          : startValues.documentType;
      } else {
        complete.documentType = 'other';
      }
      if (complete?.user?.userType) {
        Object.keys(userTypes).forEach(key => {
          const type = userTypes[key];
          if (type.token === complete.user.userType) {
            complete.userType = type.id;
          }
        });
      } else {
        complete.userType = '';
      }
      delete complete.state;
      delete complete.isMain;
      delete complete.user;
      delete complete.lastSignatureDate;
      delete complete.pdfSheet;
      delete complete.photoDocumentBack;
      delete complete.photoDocumentFront;
      delete complete.photoDocumentsUrl;
      delete complete.urlRiskReport;
      delete complete.signedPdfStatus;
      delete complete.core2Uuid;
      setClientData(complete);
    }
  }, [finalClient, client]);

  //Client mutations
  const [updateClientMutation, { loading: loadingUpdate }] = useMutation(
    UDPATE_CLIENT_INTERESTED_INVESTORS,
    getOptions({
      mutationName: 'updateClient',
      modelName: 'client',
      message: 'Cliente actualizado con éxito.',
      refetchQueries: [
        {
          query: LIST_CLIENT_USERS,
          variables: {
            search: null,
            first: 10,
            afterCursor: null,
            last: null,
            beforeCursor: null,
          },
        },
      ],
      enqueueSnackbar,
    }),
  );

  const updateClient = useMutation(
    UPDATE_USER,
    getOptions({
      mutationName: 'updateUser',
      modelName: 'user',
      enqueueSnackbar,
      completeCallback: (data, errors) => {
        updateClientMutation({
          variables: {
            id: finalClient?.id,
            input: saveClientData,
          },
        });
      },
    }),
  );
  const createClient = useMutation(
    CREATE_USER,
    getOptions({
      mutationName: 'createUser',
      modelName: 'user',
      message: 'Cliente creado con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        {
          query: LIST_CLIENT_USERS,
          variables: {
            search: null,
            first: 10,
            afterCursor: null,
            last: null,
            beforeCursor: null,
          },
        },
      ],
      completeCallback: history.goBack,
    }),
  );
  const deleteClient = useMutation(
    DELETE_USER,
    getOptions({
      mutationName: 'deleteUser',
      modelName: 'user',
      message: 'Cliente eliminado con éxito.',
      enqueueSnackbar,
      update(cache) {
        if (cache.data.data.ROOT_QUERY.listUsers) {
          const { listUsers } = cache.readQuery({
            query: LIST_CLIENT_USERS,
          });
          listUsers.edges = listUsers.edges.filter(e => e.node.id !== id);
          cache.writeQuery({
            query: LIST_CLIENT_USERS,
            data: { listUsers },
          });
        }
        history.push('/administrador/clientes');
      },
    }),
  );

  // SELECT QUERIES
  const {
    loading: DocumentTypesLoading,
    error: DocumentTypesError,
    data: DocumentTypesData,
  } = useQuery(LIST_DOCUMENT_TYPES);
  if (DocumentTypesError) {
    console.error('Client Form - list document types', DocumentTypesError);
  }

  const { data: canalActualData } = useQuery(LIST_CANAL_ACTUAL);
  const { data: canalOrigenData } = useQuery(LIST_CANAL_ORIGEN);

  const data = [
    {
      label: 'Empresa Promotora',
      name: 'promoterCompany',
      inputVariant: INPUT_VARIANT.select,
      gridMD: 6,
      data: edgeToList(promoterCompaniesData, 'listPromoterCompany'),
      mapData: { value: 'id', label: 'businessName' },
      onChange: promotersPerCompany,
    },
    {
      label: 'Promotor',
      name: 'promoter',
      inputVariant: INPUT_VARIANT.select,
      gridMD: 6,
      data: promoters,
      mapData: { value: 'id', label: 'fullName' },
      disabled: isOnlyCore,
    },
    {
      label: 'Asesor de Core',
      name: 'coreConsultant',
      inputVariant: INPUT_VARIANT.select,
      gridMD: 6,
      data: corePromoters,
      mapData: { value: 'id', label: 'fullName' },
    },
    {
      label: 'Nombre',
      name: 'firstName',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
      disabled: isEdit,
    },
    {
      label: 'Apellido paterno',
      name: 'lastName',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
      disabled: isEdit,
    },
    {
      label: 'Apellido materno',
      name: 'maternalSurname',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
      disabled: isEdit,
    },
    {
      label: 'Email',
      name: 'email',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.text,
    },
    {
      label: 'Tipo de documento',
      name: 'documentType',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: edgeToList(DocumentTypesData, 'listDocumentTypes'),
      mapData: { value: 'id', label: 'name' },
      disabled: isEdit,
    },
    {
      label: 'Número de documento',
      name: 'documentNumber',
      gridMD: 4,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.number,
      disabled: isEdit,
    },
    {
      label: 'Rol',
      name: 'userType',
      gridMD: 12,
      inputVariant: INPUT_VARIANT_SIMPLE_FORM.select,
      data: userTypes,
      mapData: { value: 'id', label: 'name' },
    },
    {
      isField: false,
      section: 'Radiografía',
      fields: [
        {
          label: 'Nombre de Comunicación',
          name: 'nombreComunicacion',
          gridMD: 6,
          inputVariant: INPUT_VARIANT.text,
        },
        {
          label: 'Enviar Comunicación',
          name: 'enviarComunicacion',
          inputVariant: INPUT_VARIANT.select,
          gridMD: 6,
          data: [
            { value: true, name: 'Si' },
            { value: false, name: 'No' },
          ],
          mapData: { value: 'value', label: 'name' },
        },
        {
          label: 'Nombre Canal Actual',
          name: 'nombreCanalActual',
          gridMD: 6,
          inputVariant: INPUT_VARIANT.text,
        },
        {
          label: 'Canal Actual',
          name: 'canalActual',
          inputVariant: INPUT_VARIANT.select,
          gridMD: 6,
          data: canalActualData ? canalActualData.canalesActual : null,
          mapData: { value: 'value', label: 'name' },
        },
        {
          label: 'Nombre Canal Origen',
          name: 'nombreCanalOrigen',
          gridMD: 6,
          inputVariant: INPUT_VARIANT.text,
        },
        {
          label: 'Canal de Origen',
          name: 'canalOrigen',
          inputVariant: INPUT_VARIANT.select,
          gridMD: 6,
          data: canalOrigenData ? canalOrigenData.canalesOrigen : null,
          mapData: { value: 'value', label: 'name' },
        },
        {
          label: 'Correo Intermediario',
          name: 'correoIntermediario',
          gridMD: 6,
          inputVariant: INPUT_VARIANT.text,
        },
      ],
    },
  ];

  function formatBeforeSubmit(values) {
    const newValues = { ...values };
    const clientValues = { ...values };
    delete clientValues.userType;
    delete clientValues.isStaff;
    delete clientValues.signedPdfStatus;
    delete clientValues.core2Uuid;
    if (!isEdit) {
      newValues.isInterestedInvestor = false;
    }
    if (isOnlyCore) clientValues.promoter = '';
    setSaveClientData(clientValues);
    return newValues;
  }

  return (
    <>
      <SimpleForm
        initialValues={startValues}
        formatBeforeSubmit={formatBeforeSubmit}
        validateFunction={validationFunctionClient}
        validateSchema={validationSchemaClient}
        data={data}
        model={clientData}
        id={id}
        modelName="Clientes"
        routeName="/administrador/clientes"
        isEdit={isEdit}
        updateMutation={updateClient}
        createMutation={createClient}
        deleteMutation={deleteClient}
        hasCancel
        onCancel={history.goBack}
      />
    </>
  );
}

ClientForm.propTypes = {
  client: PropTypes.shape(),
  id: PropTypes.string,
  isEdit: PropTypes.bool,
};

ClientForm.defaultProps = {
  client: null,
  id: '',
  isEdit: false,
};

export default ClientForm;
