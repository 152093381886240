import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';

import { Tooltip, Button, Container, IconButton } from '@material-ui/core';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import AddIcon from '@material-ui/icons/Add';

import CustomCard from '../../../../CustomCard';
import CustomModal from '../../../../CustomModal';
import { textLabels } from '../../../../../translations/components/MUIDataTable';
import useStyles from './styles';
import { generateColumn } from 'src/utils/commonFunctions';
import CustomOptions from 'src/components/CustomOptions';
import LinkedPeopleInsideList from '../LinkedPeopleInsideList';
import LinkedPeopleForm from '../LinkedPeopleForm';

function LinkedPeopleList(props) {
  const { setParticipants, data } = props;
  const styles = useStyles();

  const [showForm, setShowForm] = useState(false);
  const [showListModal, setShowListModal] = useState(false);
  const [participantsList, setParticipantsList] = useState(data);
  const [selectedClientID, setSelectedClientID] = useState(null);

  const columns = [
    generateColumn('Nombre', 'clientName'),
    generateColumn('Opciones', 'clientID', {
      sort: true,
      filter: false,
      customBodyRender: value => {
        const id = value;
        let options = ['ocustom'];
        return (
          <CustomOptions
            id={id}
            model="Vinculado"
            options={options}
            customOption={
              <>
                <Tooltip arrow title="Lista de Vinculados" placement="top">
                  <IconButton
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={styles.optionButtons}
                    onClick={() => {
                      setSelectedClientID(id);
                      setShowListModal(true);
                    }}
                  >
                    <DescriptionIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title="Agregar" placement="top">
                  <AddIcon
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={styles.optionButtons}
                    onClick={() => {
                      setSelectedClientID(id);
                      setShowForm(true);
                    }}
                  >
                    <PageviewOutlinedIcon />
                  </AddIcon>
                </Tooltip>
              </>
            }
          />
        );
      },
    }),
  ];

  useEffect(() => {
    setParticipants(participantsList);
  }, [participantsList]);

  const options = {
    filterType: false,
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: false,
    download: false,
    search: false,
    viewColumns: false,
    pagination: false,
    textLabels,
  };

  const closeForm = () => {
    setSelectedClientID(null);
    setShowForm(false);
  };

  return (
    <>
      <CustomCard disableFlex>
        <MUIDataTable
          data={participantsList}
          columns={columns}
          options={options}
        />
      </CustomCard>
      <CustomModal
        maxWidth="lg"
        className={styles.container}
        showModal={showListModal}
        title="Lista de Vinculados"
        onClosePress={() => {
          setShowListModal(false);
          closeForm();
        }}
        fullWidth
      >
        <LinkedPeopleInsideList clientID={selectedClientID} />
      </CustomModal>
      <CustomModal
        title="Vinculado"
        showModal={showForm}
        onClosePress={() => closeForm()}
      >
        <LinkedPeopleForm
          clientId={selectedClientID}
          linkedPeopleData={null}
          closeForm={() => closeForm()}
        />
      </CustomModal>
    </>
  );
}

LinkedPeopleList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setParticipants: PropTypes.func.isRequired,
};

export default LinkedPeopleList;
