import React, { useState, useEffect, useRef } from 'react';

// Material Resources
import {
  FormControl,
  FormHelperText,
  MenuItem,
  InputLabel,
  Grid,
  // Select,
} from '@material-ui/core';

// Others
import PropTypes from 'prop-types';
import { Select } from 'formik-material-ui';
import { ErrorMessage, useFormikContext } from 'formik';
// Styles & Images
import useStyles from './styles';
import CustomSelectClient from './type-select/client-select';
import CustomSelectAdmin from './type-select/admin-select';

function CustomSelect(props) {
  // data : Array of elements, example: [{ country_name: "Peru", country_val: "PE" }]
  // mapData: Define which object property use as value and label, example: {value: "country_val", label: "country_name"}
  // mapData default => {value: "value", label: "label"}
  const {
    id,
    disabled,
    name,
    label,
    data,
    mapData,
    onChange,
    error,
    isStepForm,
    linkedPeopleData,
    isDirect,
    isLinkedSelect
  } = props;

  // const [currentPath, setCurrentPath] = useState('');
  const [isAdminPath, setIsAdminPath] = useState(false);
  const [autocompleteSelect, setAutocompleteSelect] = useState('');

  const handleInfoToAutocomplete = code => {
    setAutocompleteSelect(code);
  };
  useEffect(() => {
    const path = window.location.pathname;
    // setCurrentPath(path);

    if (path.includes('administrador')) {
      setIsAdminPath(true);
    } else {
      setIsAdminPath(false);
    }
  }, [isAdminPath]);


  
  return (
    <>
      {isAdminPath ? (
        <>
          <CustomSelectAdmin
            id={id}
            disabled={disabled}
            name={name}
            label={label}
            data={data}
            mapData={mapData}
            onChange={onChange}
            error={error}
            isStepForm={isStepForm}
          />
        </>
      ) : (
        <>
          <CustomSelectClient
            id={id}
            disabled={disabled}
            name={name}
            label={label}
            data={data}
            mapData={mapData}
            onChange={onChange}
            error={error}
            isStepForm={isStepForm}
            linkedPeopleData={linkedPeopleData}
            autocomplete={autocompleteSelect}
            handleInfoToAutocomplete={handleInfoToAutocomplete}
            isDirect={isDirect}
            isLinkedSelect={isLinkedSelect}
          />
        </>
      )}
    </>
  );
}
CustomSelect.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mapData: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isStepForm: PropTypes.bool,
  linkedPeopleData: PropTypes.shape(),
  error: PropTypes.string,
  isDirect: PropTypes.bool,
};
CustomSelect.defaultProps = {
  mapData: { value: 'value', label: 'label' },
  onChange: () => null,
  disabled: false,
  error: '',
  isStepForm: false,
  autocomplete: '',
  handleInfoToAutocomplete: id => null,
};

export default CustomSelect;
