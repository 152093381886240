import React, { useCallback } from 'react';

// Material Resources
import MuiTextField from '@material-ui/core/TextField';

// Others
import { useFieldToTextField } from 'formik-material-ui';

function InputOnlyText(props) {
  const customize = useCallback(([, , helpers]) => {
    return {
      onChange: event => {
        const { value } = event.target;
        const reg = /^[a-zñáéíóúA-ZÑÁÉÍÓÚ ]*$/;
        if (reg.test(value) || value === '') {
          helpers.setValue(value);
        }
      },
    };
  }, []);
  return <MuiTextField {...useFieldToTextField(props, customize)} />;
}

export default InputOnlyText;
