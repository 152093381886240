/*
 * Common Functions for graphql data parse
 */
import React from 'react';
import moment from 'moment';

import { NOTIFICATION_DURATION } from './constants';
import methods from './formErrorFormat';
import CustomColumnHeader from '../components/TableComponents/CustomColumnHeader';
import { Base64 } from 'js-base64';

// data: Response from graphql backend
// listName: name of the object return in the query
// eslint-disable-next-line import/prefer-default-export
export function edgeToList(data, listName) {
  if (data) {
    return data[listName].edges.map(element => {
      return element.node;
    });
  }
  return [];
}

// true/false to string
export function parseBool(value) {
  if (value) return 'true';
  return 'false';
}

// ENUM TYPES
const ENUM_IDS = {
  true: true,
  false: false,
  PB: 'PUBLIC',
  PR: 'PRIVATE',
  VI: 'VARIABLE',
  FI: 'FIXED',
  FF: 'FUTURE_FLOW',
  QV: 'QUOTE_VALUE',
  FR: 'FIXED_RATE',
  L: 'LOCAL',
  I: 'INTERNATIONAL',
  RS: 'REAL_STATE',
  PD: 'PRIVATE_DEBT',
  VC: 'VENTURE_CAPITAL',
  FOF: 'FUND_OF_FUNDS',
  HF: 'HEDGE_FUNDS',
  M: 'MALE',
  F: 'FEMALE',
};
export function parseEquivalent(value) {
  return ENUM_IDS[value];
}

export function errorMessage(errors, enqueueSnackbar, options = {}) {
  const { callback } = options;
  if (errors && Array.isArray(errors)) {
    errors.forEach(i => {
      let messageField = `${i.field} : ${i.message}`;
      const formatError = methods.formatErrorName(i.field);
      const message = i.field && i.field !== 'key' ? formatError : i.message;
      enqueueSnackbar(message, {
        autoHideDuration: NOTIFICATION_DURATION,
        variant: 'error',
        onEntered: () => {
          typeof callback === 'function' && callback();
        },
      });
    });
  } else if (errors && typeof errors === 'string') {
    const message = errors;
    enqueueSnackbar(message, {
      autoHideDuration: NOTIFICATION_DURATION,
      variant: 'error',
      onEntered: () => {
        typeof callback === 'function' && callback();
      },
    });
  } else if (errors) {
    const message = errors.message || 'Error en la operación';
    enqueueSnackbar(message, {
      autoHideDuration: NOTIFICATION_DURATION,
      variant: 'error',
      onEntered: () => {
        typeof callback === 'function' && callback();
      },
    });
  }
}

export function successMessage(message, enqueueSnackbar, options = {}) {
  const { callback, ...otherOptions } = options;
  enqueueSnackbar(message, {
    autoHideDuration: NOTIFICATION_DURATION,
    variant: 'success',
    onEntered: () => {
      typeof callback === 'function' && callback();
    },
    ...otherOptions,
  });
}

export function getOptions(values) {
  const {
    mutationName,
    modelName,
    message,
    enqueueSnackbar,
    completeCallback = () => {},
    completeErrorCallback = () => {},
    errorCallback = () => {},
    update,
    refetchQueries,
    awaitRefetchQueries = true,
  } = values;
  return {
    onError: error => {
      const { graphQLErrors } = error;
      errorMessage(graphQLErrors, enqueueSnackbar, {
        callback: () => errorCallback(error),
      });
    },
    onCompleted: response => {
      const { errors } = response[mutationName];
      const error = response[mutationName]['error'];
      const data =
        response[mutationName][modelName] || response[mutationName].ok;

      if (error) {
        errorMessage(error, enqueueSnackbar, {
          callback: () => completeErrorCallback(data, error),
        });
      } else if (data) {
        if (message) {
          successMessage(message, enqueueSnackbar, {
            callback: () => completeCallback(data, errors),
          });
        } else {
          completeCallback(data, errors);
        }
      } else {
        errorMessage(errors, enqueueSnackbar, {
          callback: () => completeErrorCallback(data, errors),
        });
      }
    },
    refetchQueries,
    awaitRefetchQueries,
    update,
  };
}

/**
 * This method assumes that the base64 string has always
 * this structure -> "b'...'". Therefore, it deletes the
 * first two elements and the last one.
 * @param {string} base64
 * @param {string} fileName
 * @param {string} fileExtension
 */
export function downloadBase64Document(base64, fileName, fileExtension) {
  const { length } = base64;
  const src = `data:application/${fileExtension};base64,${base64.slice(
    2,
    length - 1,
  )}`;
  const link = document.createElement('a');
  link.href = src;
  link.download = fileName;
  link.click();
}

/**
 * Use for plain text transformation
 * @param {string} base64
 * @param {string} fileName
 */
export function downloadBase64PlainText(base64, fileName) {
  const src = `data:application/octet-stream;base64,${base64}`;
  const link = document.createElement('a');
  link.href = src;
  link.download = `${fileName}.csv`;
  link.click();
}

export function downloadBase64xlsx(base64, fileName) {
  const src = `data:application/octet-stream;base64,${base64}`;
  const link = document.createElement('a');
  link.href = src;
  link.download = `${fileName}.xlsx`;
  link.click();
}

/**
 * Create column structure by passing few params.
 *
 * @param {String} label Text to display in the header
 * @param String} name Attribute name in the list to render
 * @param {Boolean=false} sort Sort enabled
 * @param {Boolean=false} customHeader enabled
 */
export function generateColumn(
  label,
  name,
  options = { sort: true, filter: false, display: true },
) {
  return {
    name,
    label,
    options: {
      display: options.display ? options.display : true,
      sort: options.sort,
      filter: options.filter,
      customHeadRender: (columnMeta, updateDirection) => (
        <CustomColumnHeader
          id={columnMeta.index}
          key={columnMeta.index}
          columnMeta={columnMeta}
          updateDirection={updateDirection}
          sort={options.sort}
        />
      ),
      ...options,
    },
  };
}

// Return States to Signatures
export function parseSignatureState(state) {
  switch (state) {
    case 'BEFORE_SIGNATURE':
      return 'Antes de firmado';
      break;
    case 'IN_PROCESS':
      return 'En proceso';
      break;
    case 'SIGNED':
      return 'Firmado';
      break;
    case 'SIGNING_ERROR':
      return 'Error de firmado';
      break;
    case 'DOWNLOAD_ERROR':
      return 'Error de descarga';
      break;
  }
}
// Return Javascript Date Object
export function stringToDate(date) {
  if (typeof date === 'string') {
    return new Date(date.replace(/-/g, '/').replace(/T.+/, ''));
  }
  return date;
}

// Return a number in decimal notation
export function scientificToDecimal(number, decimal = 10) {
  return Number(number)
    .toFixed(decimal)
    .replace(/\.?0+$/, '');
}

/**
 * Parse dates inside an object
 *
 * @param {Object} obj object from where parse dates.
 */
export function parseDatesInObject(obj) {
  const newObject = JSON.parse(JSON.stringify(obj));
  Object.keys(newObject).forEach(k => {
    if (k.match(/date|Date/)) {
      newObject[k] = moment(newObject[k]).format('DD/MM/YYYY');
    }
  });
  return newObject;
}

export function parseJwt(token) {
  var base64Payload = token.split('.')[1];
  var payload = Buffer.from(base64Payload, 'base64');
  return JSON.parse(payload.toString());
}

/**
 * To Money Representation.
 *
 * @param {number} amount of money as number to convert
 */
export function toMoneyRepresentation(amount, minFracDigits = 2) {
  return amount.toLocaleString('es-PE', {
    minimumFractionDigits: minFracDigits,
  });
}

/**
 * To get decimal with round to the floor or ceil.
 *
 * @param {string} type adjust
 * @param {number} value to adjust
 * @param {exp} exp to shift the adjust
 */
export function decimalAdjust(type, value, exp) {
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split('e');
  value = Math[type](+(value[0] + 'e' + (value[1] ? +value[1] - exp : -exp)));
  // Shift back
  value = value.toString().split('e');
  return +(value[0] + 'e' + (value[1] ? +value[1] + exp : exp));
}

//put thousand commas on numbers
export function numberWithCommas(x) {
  var parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export function getDBIdFromGraphqlId(graphqlId, schema) {
  const rawId = Base64.decode(graphqlId);
  const regexp = /(\w+):(\d+)/;
  const arr = regexp.exec(rawId);
  if (schema && schema !== arr[1]) {
    throw new Error('Schema is not correct');
  }
  return parseInt(arr[2], 10);
}
