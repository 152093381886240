export const LINKED_TYPES = [
  {
    value: 'SP',
    label: 'Cónyuge/Conviviente',
    enum: 'SPOUSE',
  },
  {
    value: 'PGP',
    label: 'Abuelo/a Paterno',
    enum: 'PATERNAL_GRAND_PARENT',
  },
  {
    value: 'MGP',
    label: 'Abuelo/a Materno',
    enum: 'MATERNAL_GRAND_PARENT',
  },
  {
    value: 'P',
    label: 'Padre/Madre',
    enum: 'PARENT',
  },
  {
    value: 'PIL',
    label: 'Suegro/a',
    enum: 'PARENT_IN_LAW',
  },
  {
    value: 'S',
    label: 'Hermano/a',
    enum: 'SIBLING',
  },
  {
    value: 'SIL',
    label: 'Cuñado/a',
    enum: 'SIBLING_IN_LAW',
  },
  {
    value: 'C',
    label: 'Hijo/a',
    enum: 'CHILD',
  },
  {
    value: 'CIL',
    label: 'Yerno/Nuera',
    enum: 'CHILD_IN_LAW',
  },
  {
    value: 'GC',
    label: 'Nieto/a',
    enum: 'GRANDCHILD',
  },
  {
    value: 'SC',
    label: 'Hijastro/a',
    enum: 'STEPCHILD',
  },
  {
    value: 'O',
    label: 'Otro',
    enum: 'OTHER',
  },
  {
    value: 'ID',
    label: 'Es Directo',
    enum: 'IS_DIRECT',
  },
];

export const POSITION_CHOICES = [
  {
    value: 'S',
    label: 'Accionistas',
    enum: 'SHAREHOLDER',
  },
  {
    value: 'DIR',
    label: 'Directores',
    enum: 'DIRECTOR',
  },
  {
    value: 'M',
    label: 'Administradores del grupo',
    enum: 'MANAGER',
  },
  {
    value: 'ECO',
    label: 'Gestores Externos',
    enum: 'EXTERNAL_CONTROL_OFFICER',
  },
  {
    value: 'MIC',
    label:
      'Miembros de los Comités de Core Capital (Inversiones, Vigilancia y Riesgos)',
    enum: 'MEMBER_INVESTMENT_COMMITTEE',
  },
  {
    value: 'ICO',
    label: 'Funcionarios del grupo',
    enum: 'INTERNAL_CONTROL_OFFICER',
  },
  {
    value: 'R',
    label: 'Representantes del grupo',
    enum: 'REPRESENTATIVE',
  },
  {
    value: 'C',
    label: 'Asesores del grupo que tengan acceso a información privilegiada',
    enum: 'CONSULTANT',
  },
  {
    value: 'P',
    label: 'Promotores del grupo',
    enum: 'PROMOTER',
  },
  {
    value: 'E',
    label: 'Trabajadores del grupo',
    enum: 'EMPLOYEE',
  },
  {
    value: 'W',
    label: 'Locadores del grupo',
    enum: 'WORKER',
  },
  {
    value: 'AP',
    label:
      'Socios de las auditoras del grupo que tengan acceso a información privilegiada',
    enum: 'AUDIT_PARTNER',
  },
  {
    value: 'A',
    label:
      'Funcionarios de las auditoras del grupo que tengan acceso a información privilegiada',
    enum: 'AUDITOR',
  },
];

export const COMPANY_CHOICES = [
  {
    value: 'C',
    label: 'Core Capital SAFI',
    enum: 'CORE',
  },
  {
    value: 'P',
    label: 'Produktiva',
    enum: 'PRODUKTIVA',
  },
  {
    value: 'E',
    label: 'Edifica',
    enum: 'EDIFICA',
  },
];

export const RELATION_CHOICES = [
  {
    value: 'D',
    label: 'Directo (Yo ocupo el cargo)',
    enum: 'DIRECT',
  },
  {
    value: 'I',
    label: 'Indirecto (Soy familiar de alguien que ocupa el cargo)',
    enum: 'INDIRECT',
  },
];
