import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import moment from 'moment';
// GraphQl
import { useLazyQuery } from '@apollo/react-hooks';

// Components & Others
import LoadingIndicator from '../../../LoadingIndicator';
import { LIST_PARTICIPANT_RAISING_REJECTED } from '../queries';
import {
  generateColumn,
  edgeToList,
  decimalAdjust,
} from '../../../../utils/commonFunctions';
import NumberFormat from 'react-number-format';
import { textLabels } from '../../../../translations/components/MUIDataTable';
import {
  POSITION_CHOICES,
  COMPANY_CHOICES,
} from '../../../Cognito/StepThreeForm/LinkedPeople/constants';

// styles and icons
import useStyles from './styles';

function VinculatedRejectedList() {
  const classes = useStyles();
  const [participationList, setParticipationList] = useState([]);
  const [currencySymbol, setCurrencySymbol] = useState('');

  const [getParticipants, { error, loading }] = useLazyQuery(
    LIST_PARTICIPANT_RAISING_REJECTED,
    {
      fetchPolicy: 'no-cache',
      onCompleted: dataList => {
        if (dataList) {
          const participationListTmp = edgeToList(
            dataList,
            'listRejectedParticipantRaising',
          )
            .filter(({ lastParticipation }) => !!lastParticipation)
            .map(participation => {
              const {
                id,
                lastParticipation,
                createdDate,
                numFee,
                amount,
                client,
                raising,
                classFund,
                validated,
              } = participation;
              setCurrencySymbol(raising.fund.currency.symbol);
              const newParticipation = {
                options: {
                  id: id,
                  state: lastParticipation.state,
                  validated: validated,
                  participantID: client.id,
                },
                fund: raising.fund.businessName,
                order: raising.number,
                clientID: client.id,
                state: lastParticipation.state,
                dateEntry: createdDate,
                quotes: numFee,
                validated: validated,
                classFund: classFund ? classFund.name : '',
                amount: decimalAdjust('ceil', amount, -2),
                participants: [],
                percentajes: [],
                linkedPeoples: [],
              };
              newParticipation.participants.push(client.user);
              newParticipation.percentajes.push(
                lastParticipation.participationPercentage,
              );
              edgeToList(client, 'linkedPeople').forEach(item => {
                if (item.active) {
                  newParticipation.linkedPeoples.push({
                    name: item.firstName + ' ' + item.lastName,
                    document: item.documentNumber,
                    company: COMPANY_CHOICES.find(
                      element => element.value == item?.company,
                    )?.label,
                    position: POSITION_CHOICES.find(
                      element => element.value == item?.position,
                    )?.label,
                  });
                }
              });
              edgeToList(lastParticipation, 'coParticipants').forEach(
                ({ client: subClient, participationPercentage }) => {
                  edgeToList(subClient, 'linkedPeople').forEach(item => {
                    if (item.active) {
                      newParticipation.linkedPeoples.push({
                        name: item.firstName + ' ' + item.lastName,
                        document: item.documentNumber,
                        company: COMPANY_CHOICES.find(
                          element => element.value == item?.company,
                        )?.label,
                        position: POSITION_CHOICES.find(
                          element => element.value == item?.position,
                        )?.label,
                      });
                    }
                  });
                  newParticipation.participants.push(subClient.user);
                  newParticipation.percentajes.push(participationPercentage);
                },
              );
              return newParticipation;
            });
          setParticipationList(participationListTmp);
        }
      },
    },
  );

  if (error) {
    console.error('list clients', error);
  }

  useEffect(() => {
    getParticipants();
  }, []);

  const columnOptions = {
    filter: false,
    sort: true,
  };

  const columns = [
    generateColumn('Fondo', 'fund', {
      customBodyRender: value => <span>{value}</span>,
    }),
    generateColumn('Levantamiento', 'order', {
      customBodyRender: value => <span>{value}</span>,
    }),
    generateColumn('Clase', 'classFund'),
    generateColumn('Participante', 'participants', {
      ...columnOptions,
      customBodyRender: participants => (
        <div className={classes.multilineCell}>
          {participants.map(
            ({ firstName, lastName, maternalSurname, isVinculated }) =>
              isVinculated ? (
                <span>
                  <b>{`${firstName} ${lastName} ${maternalSurname}`}</b> |{' '}
                </span>
              ) : (
                <span>{`${firstName} ${lastName} ${maternalSurname}`}</span>
              ),
          )}
        </div>
      ),
    }),
    generateColumn('Cuotas', 'quotes'),
    generateColumn('Monto', 'amount', {
      ...columnOptions,
      customBodyRender: amount => (
        <>
          {`${currencySymbol} `}
          <NumberFormat
            displayType="text"
            fixedDecimalScale
            decimalScale={2}
            thousandSeparator
            value={amount}
          />
        </>
      ),
    }),
    generateColumn('Fecha', 'dateEntry', {
      ...columnOptions,
      customBodyRender: value => moment(value).format('YYYY-MM-DD HH:MM:SS'),
    }),
    generateColumn('Porcentaje', 'percentajes', {
      ...columnOptions,
      customBodyRender: percentajes => (
        <div className={classes.multilineCell}>
          {percentajes.map((percentaje, i) =>
            i > 0 ? (
              <span> | {percentaje}% |</span>
            ) : (
              <span>{percentaje}%</span>
            ),
          )}
        </div>
      ),
    }),
    generateColumn('Vinculado', 'linkedPeoples', {
      ...columnOptions,
      customBodyRender: linkedPeoples => (
        <div className={classes.multilineCell}>
          {linkedPeoples.map(({ name }) =>
            name ? (
              <span>
                {` ${name}`}
                <b>{' |'}</b>
              </span>
            ) : (
              <span>-</span>
            ),
          )}
        </div>
      ),
    }),
    generateColumn('Documento', 'linkedPeoples', {
      ...columnOptions,
      customBodyRender: linkedPeoples => (
        <div className={classes.multilineCell}>
          {linkedPeoples.map(({ document }) =>
            document ? (
              <span>
                {` ${document}`}
                <b>{' |'}</b>
              </span>
            ) : (
              <span>-</span>
            ),
          )}
        </div>
      ),
    }),
    generateColumn('Empresa', 'linkedPeoples', {
      ...columnOptions,
      customBodyRender: linkedPeoples => (
        <div className={classes.multilineCell}>
          {linkedPeoples.map(({ company }) =>
            company ? (
              <span>
                {` ${company}`}
                <b>{' |'}</b>
              </span>
            ) : (
              <span>-</span>
            ),
          )}
        </div>
      ),
    }),
    generateColumn('Cargo', 'linkedPeoples', {
      ...columnOptions,
      customBodyRender: linkedPeoples => (
        <div className={classes.multilineCell}>
          {linkedPeoples.map(({ position }) =>
            position ? (
              <span>
                {` ${position}`}
                <b>{' |'}</b>
              </span>
            ) : (
              <span>-</span>
            ),
          )}
        </div>
      ),
    }),
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    print: false,
    filter: true,
    download: false,
    textLabels,
  };

  return loading ? (
    <LoadingIndicator />
  ) : (
    <>
      <MUIDataTable
        title="Vinculados Desaprobados"
        data={participationList}
        columns={columns}
        options={options}
      />
    </>
  );
}

export default VinculatedRejectedList;
