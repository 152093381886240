import React from 'react';
import style from "./text.module.css"
const ResponsiveText = () => {
  return (
    <div className={`${style.responsiveContainer}`}>
      <p className={`${style.responsiveText}`}>
        * En caso de fallecimiento de un familiar directo, por favor, no incluir su información en el llenado de la ficha.
      </p>
    </div>
  );
};

export default ResponsiveText;