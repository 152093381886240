import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Link,
  Tooltip,
  Divider,
} from '@material-ui/core';
import {
  InsertChart as InsertChartIcon,
  Dashboard as DashboardIcon,
  BarChart as BarChartIcon,
  DonutSmall as DonutSmallIcon,
  AttachMoney as AttachMoneyIcon,
  AccountBalance as AccountBalanceIcon,
  MultilineChart as MultilineChartIcon,
  Settings as SettingsIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  AccountTree as AccountTreeIcon,
  Business as BusinessIcon,
  Apartment as ApartmentIcon,
  Description as DescriptionIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  Gavel as GavelIcon,
  LocalAtm as LocalAtmIcon,
  School as SchoolIcon,
  AllOut as AllOutIcon,
  VerifiedUser as VerifiedUserIcon,
  Face as FaceIcon,
  DirectionsWalk as DirectionsWalkIcon,
  Work as WorkIcon,
  FileCopy as FileCopyIcon,
  InsertDriveFile as InsertDriveFileIcon,
  People as PeopleIcon,
  Build as BuildIcon,
  AssignmentInd as PersonSharpIcon,
  PersonAddDisabled as PersonAddDisabledIcon,
  Person as PersonAddIcon,
  Create as CreateIcon,
  NewReleases as NewsIcon,
  AttachFileSharp,
} from '@material-ui/icons';

import AssessmentIcon from '@material-ui/icons/Assessment';
import ClassIcon from '@material-ui/icons/Class';

import SubMenu from '../MenuComponents/SubMenu';
import useStyles from './styles';
import { getUserPermissionsSelector } from '../../store/permissionsStore/selector';
import {
  REACT_APP_ROP_URL,
  REACT_APP_COMERCIAL,
  REACT_APP_SIMULADOR,
  REACT_APP_COLLECTION_PREFIX,
} from '../../utils/constants';
import tokenGenerator from '../../providers/TokenGenerator';
import { useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from '@queries/Client';
import { getDBIdFromGraphqlId } from '../../utils/commonFunctions';

function CustomMenuItem(props) {
  const { url, name, open, icon } = props;
  const classes = useStyles();
  return (
    <Link underline="none" color="textPrimary" component={RouterLink} to={url}>
      <ListItem button key={name} dense>
        {open ? (
          <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
        ) : (
          <Tooltip title={name} placement="right">
            <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
          </Tooltip>
        )}
        <ListItemText secondary={name} />
      </ListItem>
    </Link>
  );
}
CustomMenuItem.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  icon: PropTypes.node.isRequired,
};

function AdminMenu(props) {
  const { open } = props;
  const [permissions, setPermissions] = useState(null);
  const [token, setToken] = useState('');
  const [menuState, setMenuState] = useState({
    menuSafis: false,
    menuFunds: false,
    menuEntities: false,
    menuConfiguration: false,
    menuAdministrator: false,
    menuUsers: false,
    menuClientSheet: false,
    menuSettings: false,
    documents: false,
    compliance: false,
    signatures: false,
    menuRop: false,
    menuComercial: false,
    menuEmployees: false,
    menuNews: false,
  });
  const classes = useStyles();
  const userPermissions = useSelector(getUserPermissionsSelector());
  const { loading, data: clients } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'no-cache',
  });
  const parsePermissions = useCallback(() => {
    const menuStructure = {
      safi: [],
      fondos: [],
      entidades: [],
      configuracion: [],
      administrador: [],
      usuarios: [],
      ficha_cliente: [],
      documentos: [],
      cumplimiento: [],
      firmas: [],
      archivos: [],
      colaboradores: [],
      inversionistas_desaprobados: [],
      rop: [],
      comercial: [],
      novedades: [],
    };
    userPermissions.forEach(permission => {
      const names = permission.slug.split('__');
      menuStructure[names[0]].push(names[1]);
    });
    return menuStructure;
  }, [userPermissions]);

  const handleToggle = menu => {
    setMenuState(state => ({ ...state, [menu]: !state[menu] }));
  };

  useEffect(() => {
    let id, token;
    if (clients && clients.currentUser) {
      id = getDBIdFromGraphqlId(clients?.currentUser?.id, 'UserType');
      token = tokenGenerator({
        payload: {
          id: id,
          userName: clients?.currentUser?.username,
          firstName: clients?.currentUser?.firstName,
          lastName: clients?.currentUser?.lastName,
          email: clients?.currentUser?.email,
        },
      });
    }
    setToken(token);
    setPermissions(parsePermissions());
  }, [userPermissions, setPermissions, parsePermissions, clients]);

  return (
    <>
      {permissions && (
        <div className={classes.adminMenu}>
          <CustomMenuItem
            url="/administrador"
            name="Dashboard"
            open={open}
            icon={<DashboardIcon color="secondary" />}
          />
          <Divider className={classes.divider} />
          {!!permissions.safi.length && (
            <SubMenu
              handleToggle={() => handleToggle('menuSafis')}
              isOpen={menuState.menuSafis}
              sidebarIsOpen={open}
              name="SAFI's"
              icon={<ApartmentIcon color="secondary" />}
            >
              <CustomMenuItem
                url="/administrador/safis"
                name="SAFI"
                open={open}
                icon={<DonutSmallIcon color="secondary" />}
              />
            </SubMenu>
          )}
          {!!permissions.fondos.length && (
            <SubMenu
              handleToggle={() => handleToggle('menuFunds')}
              isOpen={menuState.menuFunds}
              sidebarIsOpen={open}
              name="Administrador de Fondos"
              icon={<InsertChartIcon color="secondary" />}
            >
              {permissions.fondos.includes('fondos') && (
                <CustomMenuItem
                  url="/administrador/fondos"
                  name="Fondos"
                  open={open}
                  icon={<BarChartIcon color="secondary" />}
                />
              )}
              {permissions.fondos.includes('mercado_secundario') && (
                <CustomMenuItem
                  url="/administrador/mercado-secundario"
                  name="Mercado secundario"
                  open={open}
                  icon={<BarChartIcon color="secondary" />}
                />
              )}
              {permissions.fondos.includes('instrumentos') && (
                <CustomMenuItem
                  url="/administrador/instrumentos"
                  name="Instrumentos"
                  open={open}
                  icon={<MultilineChartIcon color="secondary" />}
                />
              )}
              {permissions.fondos.includes('fuentes') && (
                <CustomMenuItem
                  url="/administrador/fuentes"
                  name="Fuentes de Fondos"
                  open={open}
                  icon={<AssessmentIcon color="secondary" />}
                />
              )}
              {permissions.fondos.includes('fuentes') && (
                <CustomMenuItem
                  url="/administrador/clases"
                  name="Clases"
                  open={open}
                  icon={<ClassIcon color="secondary" />}
                />
              )}
            </SubMenu>
          )}
          {!!permissions.entidades.length && (
            <SubMenu
              handleToggle={() => handleToggle('menuEntities')}
              isOpen={menuState.menuEntities}
              sidebarIsOpen={open}
              name="Entidades"
              icon={<BusinessIcon color="secondary" />}
            >
              {permissions.entidades.includes('bancos') && (
                <CustomMenuItem
                  url="/administrador/bancos"
                  name="Bancos"
                  open={open}
                  icon={<AccountBalanceIcon color="secondary" />}
                />
              )}
              {permissions.entidades.includes('empresas_promotoras') && (
                <CustomMenuItem
                  url="/administrador/empresas-promotoras"
                  name="Empresas Promotoras"
                  open={open}
                  icon={<BusinessIcon color="secondary" />}
                />
              )}
            </SubMenu>
          )}
          {!!permissions.configuracion.length && (
            <SubMenu
              handleToggle={() => handleToggle('menuConfiguration')}
              isOpen={menuState.menuConfiguration}
              sidebarIsOpen={open}
              name="Configuración"
              icon={<SettingsIcon color="secondary" />}
            >
              {permissions.configuracion.includes('monedas') && (
                <CustomMenuItem
                  url="/administrador/monedas"
                  name="Monedas"
                  open={open}
                  icon={<AttachMoneyIcon color="secondary" />}
                />
              )}
              {permissions.configuracion.includes('tipos_de_cuenta') && (
                <CustomMenuItem
                  url="/administrador/tipos-de-cuenta"
                  name="Tipos de cuenta"
                  open={open}
                  icon={<BarChartIcon color="secondary" />}
                />
              )}
              {permissions.configuracion.includes('cuentas_bancarias') && (
                <CustomMenuItem
                  url="/administrador/cuentas-bancarias"
                  name="Cuentas bancarias"
                  open={open}
                  icon={<AccountBalanceWalletIcon color="secondary" />}
                />
              )}
              {permissions.configuracion.includes('categorias') && (
                <CustomMenuItem
                  url="/administrador/categorias"
                  name="Categorías"
                  open={open}
                  icon={<AccountTreeIcon color="secondary" />}
                />
              )}
            </SubMenu>
          )}
          {!!permissions.administrador.length && (
            <SubMenu
              handleToggle={() => handleToggle('menuAdministrator')}
              isOpen={menuState.menuAdministrator}
              sidebarIsOpen={open}
              name="Administrador"
              icon={<BuildIcon color="secondary" />}
            >
              {permissions.administrador.includes('tipos_de_documento') && (
                <CustomMenuItem
                  url="/administrador/tipos-de-documento"
                  name="Tipos de documento"
                  open={open}
                  icon={<DescriptionIcon color="secondary" />}
                />
              )}
              {permissions.administrador.includes('estados_civiles') && (
                <CustomMenuItem
                  url="/administrador/estados-civiles"
                  name="Estados civiles"
                  open={open}
                  icon={<SupervisedUserCircleIcon color="secondary" />}
                />
              )}
              {permissions.administrador.includes(
                'regimenes_patrimoniales',
              ) && (
                <CustomMenuItem
                  url="/administrador/regimenes-patrimoniales"
                  name="Regímenes patrimoniales"
                  open={open}
                  icon={<GavelIcon color="secondary" />}
                />
              )}
              {permissions.administrador.includes(
                'rangos_de_ingresos_mensuales',
              ) && (
                <CustomMenuItem
                  url="/administrador/ingresos-mensuales"
                  name="Rangos de ingresos mensuales"
                  open={open}
                  icon={<LocalAtmIcon color="secondary" />}
                />
              )}
              {permissions.administrador.includes('niveles_de_instruccion') && (
                <CustomMenuItem
                  url="/administrador/niveles-de-instruccion"
                  name="Niveles de instrucción"
                  open={open}
                  icon={<SchoolIcon color="secondary" />}
                />
              )}
              {permissions.administrador.includes('relacion_con_la_safi') && (
                <CustomMenuItem
                  url="/administrador/relacion-con-la-safi"
                  name="Relación con la SAF"
                  open={open}
                  icon={<AllOutIcon color="secondary" />}
                />
              )}
            </SubMenu>
          )}{' '}
          {!!permissions.usuarios.length && (
            <SubMenu
              handleToggle={() => handleToggle('menuUsers')}
              isOpen={menuState.menuUsers}
              sidebarIsOpen={open}
              name="Usuarios"
              icon={<PeopleIcon color="secondary" />}
            >
              {permissions.usuarios.includes('administradores') && (
                <CustomMenuItem
                  url="/administrador/administradores"
                  name="Administradores"
                  open={open}
                  icon={<VerifiedUserIcon color="secondary" />}
                />
              )}
              {permissions.usuarios.includes('clientes') && (
                <CustomMenuItem
                  tagId="dashboard_menu"
                  url="/administrador/clientes"
                  name="Clientes"
                  open={open}
                  icon={<FaceIcon color="secondary" />}
                />
              )}
              {permissions.usuarios.includes('inversionistas_interesados') && (
                <CustomMenuItem
                  url="/administrador/inversionistas-interesados"
                  name="Inversionistas interesados"
                  open={open}
                  icon={<DirectionsWalkIcon color="secondary" />}
                />
              )}
              {permissions.usuarios.includes('promotores') && (
                <CustomMenuItem
                  url="/administrador/promotores"
                  name="Promotores"
                  open={open}
                  icon={<WorkIcon color="secondary" />}
                />
              )}
            </SubMenu>
          )}
          {!!permissions.documentos.length && (
            <SubMenu
              handleToggle={() => handleToggle('documents')}
              isOpen={menuState.documents}
              sidebarIsOpen={open}
              name="Documentos"
              icon={<FileCopyIcon color="secondary" />}
            >
              {permissions.documentos.includes('tipos_contrato') && (
                <CustomMenuItem
                  url="/administrador/tipos-de-contrato"
                  name="Tipos de contrato"
                  open={open}
                  icon={<InsertDriveFileIcon color="secondary" />}
                />
              )}
              {permissions.documentos.includes('declaraciones_juradas') && (
                <CustomMenuItem
                  url="/administrador/declaraciones-juradas"
                  name="Declaraciones juradas"
                  open={open}
                  icon={<InsertDriveFileIcon color="secondary" />}
                />
              )}
            </SubMenu>
          )}
          {!!permissions.cumplimiento.length && (
            <SubMenu
              handleToggle={() => handleToggle('compliance')}
              isOpen={menuState.compliance}
              sidebarIsOpen={open}
              name="Cumplimiento"
              icon={<PersonSharpIcon color="secondary" />}
            >
              {permissions.cumplimiento.includes('aprobacion_laft') && (
                <CustomMenuItem
                  url="/administrador/aprobacion-laft"
                  name="Aprobación LAFT"
                  open={open}
                  icon={<PersonAddIcon color="secondary" />}
                />
              )}
              {permissions.cumplimiento.includes('aprobacion_vinculados') && (
                <CustomMenuItem
                  url="/administrador/aprobacion-vinculados"
                  name="Aprobación Vinculados"
                  open={open}
                  icon={<PeopleIcon color="secondary" />}
                />
              )}
              {permissions.cumplimiento.includes(
                'inversionistas_desaprobados',
              ) && (
                <CustomMenuItem
                  url="/administrador/inversionistas-desaprobados"
                  name="Inversionistas Desaprobados"
                  open={open}
                  icon={<PersonAddDisabledIcon color="secondary" />}
                />
              )}
              {permissions.cumplimiento.includes('aprobacion_vinculados') && (
                <CustomMenuItem
                  url="/administrador/aprobacion-vinculados/desaprobados"
                  name="Vinculados Desaprobados"
                  open={open}
                  icon={<PersonAddDisabledIcon color="secondary" />}
                />
              )}
            </SubMenu>
          )}
          {!!permissions.firmas.length && (
            <SubMenu
              handleToggle={() => handleToggle('signatures')}
              isOpen={menuState.signatures}
              sidebarIsOpen={open}
              name="Firmas"
              icon={<CreateIcon color="secondary" />}
            >
              {permissions.firmas.includes('procesos') && (
                <CustomMenuItem
                  url="/administrador/procesos"
                  name="Estados de Firmas"
                  open={open}
                  icon={<InsertDriveFileIcon color="secondary" />}
                />
              )}
              {/* {permissions.firmas.includes('firmados') && (
                <CustomMenuItem
                  url="/administrador/firmados"
                  name="Firmados"
                  open={open}
                  icon={<DoneAll color="secondary" />}
                />
              )} */}
            </SubMenu>
          )}
          {!!permissions.archivos.length && (
            <SubMenu
              handleToggle={() => handleToggle('files')}
              isOpen={menuState.files}
              sidebarIsOpen={open}
              name="Archivos"
              icon={<FileCopyIcon color="secondary" />}
            >
              {permissions.archivos.includes('carga') && (
                <CustomMenuItem
                  url="/administrador/carga"
                  name="Listado (Carga)"
                  open={open}
                  icon={<InsertDriveFileIcon color="secondary" />}
                />
              )}
            </SubMenu>
          )}
          {!!permissions.colaboradores.length && (
            <SubMenu
              handleToggle={() => handleToggle('menuEmployees')}
              isOpen={menuState.menuEmployees}
              sidebarIsOpen={open}
              name="Colaboradores"
              icon={<PeopleIcon color="secondary" />}
            >
              {permissions.colaboradores.includes('ficha') && (
                <CustomMenuItem
                  url="/administrador/ficha-colaborador"
                  name="Listado"
                  open={open}
                  icon={<FaceIcon color="secondary" />}
                />
              )}
              {permissions.colaboradores.includes('carga_masivo') && (
                <CustomMenuItem
                  url="/administrador/carga-colaborador"
                  name="Repositorio General"
                  open={open}
                  icon={<FileCopyIcon color="secondary" />}
                />
              )}
              {permissions.colaboradores.includes('carga_masivo') && (
                <CustomMenuItem
                  url="/administrador/carga-colaborador-especifico"
                  name="Repositorio por Colaborador"
                  open={open}
                  icon={<FileCopyIcon color="secondary" />}
                />
              )}
              {permissions.colaboradores.includes('carga_masivo') && (
                <CustomMenuItem
                  url="/administrador/colaborador-procesos"
                  name="Estados de Firmas"
                  open={open}
                  icon={<CreateIcon color="secondary" />}
                />
              )}
            </SubMenu>
          )}
          {!!permissions.novedades.length && (
            <SubMenu
              handleToggle={() => handleToggle('menuNews')}
              isOpen={menuState.menuNews}
              sidebarIsOpen={open}
              name="Novedades"
              icon={<NewsIcon color="secondary" />}
            >
              {permissions.novedades.includes('novedades') && (
                <CustomMenuItem
                  url="/administrador/news"
                  name="Listado"
                  open={open}
                  icon={<NewsIcon color="secondary" />}
                />
              )}
            </SubMenu>
          )}
          {!!permissions.rop.length && (
            <SubMenu
              handleToggle={() => handleToggle('menuRop')}
              isOpen={menuState.menuRop}
              sidebarIsOpen={open}
              name="ROP"
              icon={<ApartmentIcon color="secondary" />}
            >
              <Link
                underline="none"
                color="textPrimary"
                onClick={() => {
                  let url = `${REACT_APP_ROP_URL}?token=${token}`;
                  window.open(url, '_blank');
                }}
              >
                <ListItem button key="Rop" dense>
                  <ListItemIcon className={classes.icon}>
                    <AttachFileSharp color="secondary" />
                  </ListItemIcon>

                  <ListItemText secondary="Rop" />
                </ListItem>
              </Link>
            </SubMenu>
          )}
          {!!permissions.comercial.length && (
            <SubMenu
              handleToggle={() => handleToggle('menuComercial')}
              isOpen={menuState.menuComercial}
              sidebarIsOpen={open}
              name="Comercial"
              icon={<BarChartIcon color="secondary" />}
            >
              {permissions.comercial.includes('radiografia') && (
                <Link
                  underline="none"
                  color="textPrimary"
                  onClick={() => {
                    let url = `${REACT_APP_COMERCIAL}?token=${token}`;
                    window.open(url, '_blank');
                  }}
                >
                  <ListItem button key="Comercial" dense>
                    <ListItemIcon className={classes.icon}>
                      <AttachFileSharp color="secondary" />
                    </ListItemIcon>

                    <ListItemText secondary="Radiografía" />
                  </ListItem>
                </Link>
              )}
              {permissions.comercial.includes('simulador') && (
                <Link
                  underline="none"
                  color="textPrimary"
                  onClick={() => {
                    let url = `${REACT_APP_SIMULADOR}?token=${token}`;
                    window.open(url, '_blank');
                  }}
                >
                  <ListItem button key="Comercial" dense>
                    <ListItemIcon className={classes.icon}>
                      <AttachFileSharp color="secondary" />
                    </ListItemIcon>

                    <ListItemText secondary="Simulador" />
                  </ListItem>
                </Link>
              )}
            </SubMenu>
          )}
        </div>
      )}
    </>
  );
}

AdminMenu.propTypes = {
  open: PropTypes.bool.isRequired,
};
export default AdminMenu;
