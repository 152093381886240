import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Container } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks';

import Yup from 'src/utils/yup';
import { edgeToList, getOptions } from 'src/utils/commonFunctions';
import useStyles from './styles';
import SimpleForm from 'src/components/Forms/SimpleForm';
import LoadingIndicator from 'src/components/LoadingIndicator';
import { INPUT_VARIANT } from 'src/components/Forms/StepsForm/fieldsSelector';
import { LIST_DOCUMENT_TYPES } from 'src/components/Models/DocumentType/queries';
import {
  LINKED_TYPES,
  POSITION_CHOICES,
  COMPANY_CHOICES,
  RELATION_CHOICES,
} from '../constants';
import {
  GET_CLIENT_LINKED_PEOPLE,
  CREATE_CLIENT_LINKED_PEOPLE,
  UPDATE_CLIENT_LINKED_PEOPLE,
} from 'src/components/Cognito/queries';

function LinkedPeopleForm(props) {
  const { clientId, linkedPeopleData, closeForm } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [isDirect, setIsDirect] = useState(false);
  const [isIndirect, setIsIndirect] = useState(false);
  const [relativeId, setRelativeId] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);

  const startRelativeData = {
    linkedType: '',
    firstName: '',
    lastName: '',
    maternalSurname: '',
    documentType: '',
    documentNumber: '',
    company: '',
    position: '',
    relation: '',
  };

  const relativeValidationSchema = Yup.object().shape({
    linkedType: Yup.string().when({
      is: () => isIndirect,
      then: Yup.string().required(),
    }),
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    maternalSurname: Yup.string().required(),
    documentType: Yup.string().required(),
    documentNumber: Yup.string().required(),
    company: Yup.string().required(),
    position: Yup.string().required(),
    relation: Yup.string().required(),
  });

  const { loading: documentTypeLoading, data: documentTypeData } = useQuery(
    LIST_DOCUMENT_TYPES,
  );

  const { data: client } = useQuery(GET_CLIENT_LINKED_PEOPLE, {
    variables: { id: clientId },
  });

  const createRelativeMutation = useMutation(
    CREATE_CLIENT_LINKED_PEOPLE,
    getOptions({
      mutationName: 'createLinkedPeople',
      modelName: 'linkedPeople',
      message: 'Se guardó datos del vinculado con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_LINKED_PEOPLE, variables: { id: clientId } },
      ],
      completeCallback: () => closeForm(),
    }),
  );

  const updateRelativeMutation = useMutation(
    UPDATE_CLIENT_LINKED_PEOPLE,
    getOptions({
      mutationName: 'updateLinkedPeople',
      modelName: 'linkedPeople',
      message: 'Se actualizó datos del vinculado con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_LINKED_PEOPLE, variables: { id: clientId } },
      ],
      completeCallback: () => closeForm(),
    }),
  );

  const LINKED_TYPES_SHORT = [
    {
      value: 'SP',
      label: 'Cónyuge/Conviviente',
      enum: 'SPOUSE',
    },
    {
      value: 'PGP',
      label: 'Abuelo/a Paterno',
      enum: 'PATERNAL_GRAND_PARENT',
    },
    {
      value: 'MGP',
      label: 'Abuelo/a Materno',
      enum: 'MATERNAL_GRAND_PARENT',
    },
    {
      value: 'P',
      label: 'Padre/Madre',
      enum: 'PARENT',
    },
    {
      value: 'PIL',
      label: 'Suegro/a',
      enum: 'PARENT_IN_LAW',
    },
    {
      value: 'S',
      label: 'Hermano/a',
      enum: 'SIBLING',
    },
    {
      value: 'SIL',
      label: 'Cuñado/a',
      enum: 'SIBLING_IN_LAW',
    },
    {
      value: 'C',
      label: 'Hijo/a',
      enum: 'CHILD',
    },
    {
      value: 'CIL',
      label: 'Yerno/Nuera',
      enum: 'CHILD_IN_LAW',
    },
    {
      value: 'GC',
      label: 'Nieto/a',
      enum: 'GRANDCHILD',
    },
    {
      value: 'SC',
      label: 'Hijastro/a',
      enum: 'STEPCHILD',
    },
  ];

  const data = [
    {
      isField: true,
      label: 'Relación',
      name: 'relation',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.select,
      data: RELATION_CHOICES,
      mapData: { value: 'value', label: 'label' },
      onChange: handleRelationChange,
      linkedPeopleData: selectedClient,
    },
    {
      isField: true,
      label: 'Cargo',
      name: 'position',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.select,
      data: POSITION_CHOICES,
      mapData: { value: 'value', label: 'label' },
    },
    {
      isField: true,
      label: 'Compañia',
      name: 'company',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.select,
      data: COMPANY_CHOICES,
      mapData: { value: 'value', label: 'label' },
    },
    {
      isField: true,
      label: 'Vinculación',
      name: 'linkedType',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.select,
      data: LINKED_TYPES_SHORT,
      mapData: { value: 'value', label: 'label' },
      disabled: isDirect,
    },
    {
      isField: true,
      label: 'Nombres',
      name: 'firstName',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.onlyText,
      disabled: isDirect,
    },
    {
      isField: true,
      label: 'Apellido Paterno',
      name: 'lastName',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.onlyText,
      disabled: isDirect,
    },
    {
      isField: true,
      label: 'Apellido Materno',
      name: 'maternalSurname',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.onlyText,
      disabled: isDirect,
    },
    {
      isField: true,
      label: 'Tipo de documento',
      name: 'documentType',
      inputVariant: INPUT_VARIANT.select,
      gridMD: 6,
      data: edgeToList(documentTypeData, 'listDocumentTypes'),
      mapData: { value: 'id', label: 'name' },
      disabled: isDirect,
      isLinkedSelect: true,
    },
    {
      isField: true,
      label: 'Número de documento',
      name: 'documentNumber',
      gridMD: 6,
      inputVariant: INPUT_VARIANT.text,
      disabled: isDirect,
    },
  ];

  const validateRelativeData = () => {};
  const formatBeforeSubmit = v => {
    const newValues = { ...v };
    delete newValues.name;
    delete newValues.document;
    delete newValues.vinculacion;
    delete newValues.posicion;
    delete newValues.compania;
    delete newValues.relacion;
    return { ...newValues, client: clientId };
  };

  useEffect(() => {
    if (client) setSelectedClient(client.client);
    if (linkedPeopleData) {
      setRelativeId(linkedPeopleData.id);
      if (linkedPeopleData.documentType?.hasOwnProperty('id')) {
        linkedPeopleData.documentType = linkedPeopleData.documentType.id;
      }
      if (linkedPeopleData.linkedType) {
        linkedPeopleData.linkedType = linkedPeopleData.linkedType;
      }
      if (linkedPeopleData.relation == 'D') {
        setIsDirect(true);
        setIsIndirect(false);
      } else {
        setIsDirect(false);
        setIsIndirect(true);
      }
    } else setRelativeId(null);
  }, [clientId, client, linkedPeopleData]);

  function handleRelationChange(event) {
    event.preventDefault();
    if (event.target.value == 'D') {
      setIsDirect(true);
      setIsIndirect(false);
    } else {
      setIsDirect(false);
      setIsIndirect(true);
    }
  }

  if (documentTypeLoading) {
    return <LoadingIndicator />;
  }

  return (
    <Container>
      <div className={classes.formContainer}>
        <SimpleForm
          model={linkedPeopleData}
          initialValues={startRelativeData}
          validateFunction={validateRelativeData}
          validateSchema={relativeValidationSchema}
          data={data}
          modelName="Vinculado"
          hasCancel
          canBeDeleted={false}
          isEdit={!!relativeId}
          id={relativeId}
          routeName=""
          onCancel={closeForm}
          updateMutation={updateRelativeMutation}
          createMutation={createRelativeMutation}
          formatBeforeSubmit={formatBeforeSubmit}
          isDirect={isDirect}
        />
      </div>
    </Container>
  );
}

LinkedPeopleForm.propTypes = {
  clientId: PropTypes.string.isRequired,
  linkedPeopleData: PropTypes.shape(),
  closeForm: PropTypes.func.isRequired,
};

export default LinkedPeopleForm;
