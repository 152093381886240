import React, { useEffect, useState } from 'react';

// Material Resources
import { IconButton, Tooltip, Typography, TableCell } from '@material-ui/core';

import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import ModeIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'notistack';

// GraphQl
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';

// Components & Others
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import CustomOptions from 'src/components/CustomOptions';
import CustomModal from 'src/components/CustomModal';
import LoadingIndicator from 'src/components/LoadingIndicator';
import { generateColumn, edgeToList } from 'src/utils/commonFunctions';
import {
  GET_CLIENT_LINKED_PEOPLE,
  CHANGE_STATE_LINKED_PEOPLE,
} from 'src/components/Cognito/queries';
import {
  LINKED_TYPES,
  POSITION_CHOICES,
  COMPANY_CHOICES,
  RELATION_CHOICES,
} from '../constants';
import LinkedPeopleForm from '../LinkedPeopleForm';

import useStyles from '../../styles';
import { getOptions } from 'src/utils/commonFunctions';

function LinkedPeopleInsideList(props) {
  const { clientID } = props;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [showForm, setShowForm] = useState(false);
  const [selectedLinkedPeople, setSelectedLinkedPeople] = useState(null);
  const [linkedPeopleList, setLinkedPeopleList] = useState([]);

  const {
    loading: loading,
    error: lnkedPeopleError,
    data: linkedPeople,
  } = useQuery(GET_CLIENT_LINKED_PEOPLE, {
    variables: { id: clientID },
  });

  const [changeStateLinkedPeople] = useMutation(
    CHANGE_STATE_LINKED_PEOPLE,
    getOptions({
      mutationName: 'changeStateLinkedPeople',
      modelName: 'linkedPeople',
      message: 'Desvinculación actualizada con éxito.',
      enqueueSnackbar,
      refetchQueries: [
        { query: GET_CLIENT_LINKED_PEOPLE, variables: { id: clientID } },
      ],
    }),
  );

  function handleState(id) {
    changeStateLinkedPeople({
      variables: {
        id,
      },
    });
  }

  useEffect(() => {
    if (linkedPeople) {
      const tmp = [];
      edgeToList(linkedPeople.client, 'linkedPeople').forEach(element => {
        if (element.active) {
          const documentTypeId = element.documentType?.id;
          tmp.push({
            ...element,
            documentType: element.documentType.id,
            name:
              element.firstName +
              ' ' +
              element.lastName +
              ' ' +
              element.maternalSurname,
            document: element.documentType.name + ' ' + element.documentNumber,
            vinculacion: LINKED_TYPES.find(i => i.value === element.linkedType)
              .label,
            posicion: POSITION_CHOICES.find(i => i.value === element.position)
              .label,
            compania: COMPANY_CHOICES.find(i => i.value === element.company)
              .label,
            relacion: RELATION_CHOICES.find(i => i.value === element.relation)
              .label,
          });

          if (documentTypeId) {
            localStorage.setItem('documentTypeId', documentTypeId);
          }
        }
      });
      setLinkedPeopleList(tmp);
    }
  }, [linkedPeople]);

  const closeForm = () => {
    setSelectedLinkedPeople(null);
    setShowForm(false);
  };

  const columns = [
    generateColumn('Nombre', 'name'),
    generateColumn('Tipo y Nº Doc.', 'document'),
    generateColumn('Vinculación', 'vinculacion', {
      sort: false,
      filter: false,
      customBodyRender: value => (value == 'Es Directo' ? '' : value),
    }),
    generateColumn('Empresa', 'compania'),
    generateColumn('Cargo', 'posicion'),
    generateColumn('Relación', 'relacion'),
    {
      name: 'id',
      label: 'Opciones',
      options: {
        sort: false,
        customBodyRender: value => {
          const id = value;
          return (
            <CustomOptions
              id={id}
              model="Vinculado"
              options={['ocustom']}
              customOption={
                <>
                  <Tooltip arrow title="Editar" placement="top">
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.icon}
                      onClick={() => {
                        setSelectedLinkedPeople(
                          linkedPeopleList.find(i => i.id === id),
                        );
                        setShowForm(true);
                      }}
                    >
                      <ModeIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow title="Desvincular" placement="top">
                    <IconButton
                      variant="contained"
                      color="secondary"
                      size="small"
                      className={classes.icon}
                      onClick={() => {
                        handleState(id);
                      }}
                    >
                      <SyncDisabledIcon />
                    </IconButton>
                  </Tooltip>
                </>
              }
            />
          );
        },
        customHeadRender: columnMeta => {
          return (
            <TableCell
              id={columnMeta.index}
              key={columnMeta.index}
              style={{ borderBottom: 'none' }}
            >
              <Typography
                variant="subtitle1"
                align="left"
                className={classes.tableTitle}
              >
                {columnMeta.label}
              </Typography>
            </TableCell>
          );
        },
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    viewColumns: false,
    print: false,
    filter: false,
    download: false,
    search: false,
    pagination: false,
  };

  return (
    <>
      {loading ? (
        <LoadingIndicator />
      ) : (
        <>
          <MUIDataTable
            data={linkedPeopleList}
            columns={columns}
            options={options}
          />
          <CustomModal
            title="Vinculado"
            showModal={showForm}
            onClosePress={() => closeForm()}
          >
            <LinkedPeopleForm
              clientId={clientID}
              linkedPeopleData={selectedLinkedPeople}
              closeForm={() => closeForm()}
            />
          </CustomModal>
        </>
      )}
    </>
  );
}

LinkedPeopleInsideList.propTypes = {
  clientID: PropTypes.string.isRequired,
};

export default LinkedPeopleInsideList;
