import React from 'react';
import { Box, IconButton, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { styles, ModalProps, stylesModal } from './types';
import style from './style.module.css';

export default function ModalMoreInfoLinked({ open, handleClose }) {
  return (
    <Modal
      style={stylesModal}
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box style={styles}>
        <div className={style.header}>
          <h1 className={style.title}>¿Eres un Inversionista Vinculado?</h1>
          <IconButton
            onClick={handleClose}
            style={{ position: 'absolute', top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={style.content}>
          <p>
            <strong>Eres un Inversionista Vinculado si:</strong>
          </p>
          <ul>
            <li>
              {' '}
              Ocupas <strong>cargos clave</strong> en{' '}
              <strong>Core Capital SAFI, Edifica o Produktiva</strong>, tales
              como accionista, director, administrador, gestor externo, miembro
              de los comités, funcionario, epresentante, asesor con acceso a
              información privilegiada, promotor, trabajador, locador, socio o
              funcionario de auditorías.
            </li>
            <li>
              {' '}
              O si tienes <strong>familiares</strong> que ocupen alguno de estos{' '}
              <strong>cargos claves</strong>.{' '}
            </li>
          </ul>
          <p>
            <strong>Se consideran familiares:</strong>
          </p>
          <ul>
            <li>
              {' '}
              <strong>Hasta el segundo grado de consanguinidad:</strong>{' '}
              abuelos, padres, hermanos, hijos y nietos.
            </li>
            <li>
              {' '}
              <strong>Hasta el primer grado de afinidad:</strong> cónyuge o
              conviviente, suegros, cuñados, yernos, nueras e hijastros.
            </li>
          </ul>
        </div>
      </Box>
    </Modal>
  );
}
