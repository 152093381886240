import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_CURRENT_USER } from '@queries/Client';
import { useSession } from 'src/providers/SessionProvider';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router-dom';
import useStyles from './styles';
import { gql } from 'apollo-boost';
import { errorMessage, parseJwt } from 'src/utils/commonFunctions';
import NewPasswordFormLogin from '../setNewNumericPassword';
import NumericFormLogin from '../numericLogin';
import SendRecoverMailPage from 'src/containers/SendRecoverMailPage';

function FormLogin(props) {
  const { cognito, pathCognito } = props;
  const { token } = useParams();
  const [hasNewPassword, setHasNewPassword] = useState(true);
  const [showRecoverPass, setShowRecoverPass] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const { setRefreshToken } = useSession();
  const { enqueueSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);

  const { userLoading, data: user } = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'no-cache',
  });

  const LOGIN = gql`
    mutation login($username: String!, $password: String!) {
      tokenAuth(username: $username, password: $password) {
        payload
        refreshExpiresIn
      }
    }
  `;

  const [
    loginMutation,
    { data: loginData, error: loginError, client },
  ] = useMutation(LOGIN, {
    onCompleted(data) {
      const {
        tokenAuth: { payload, refreshExpiresIn },
      } = data;
      setRefreshToken({ ...payload, refreshExpiresIn });
      client.resetStore();
      setLoading(false);
    },
    onError(error) {
      console.error(error);
      setLoading(false);
    },
  });

  const CHECK_DIGIT_PASSWORD = gql`
    mutation checkDigitPasswordMutation($documentNumber: String!) {
      checkDigitPassword(documentNumber: $documentNumber) {
        ok
        hasDigit
      }
    }
  `;

  const [
    checkDigitPasswordMutation,
    { data: checkDigitPasswordData, error: checkDigitPasswordError },
  ] = useMutation(CHECK_DIGIT_PASSWORD, {
    onCompleted(data) {
      setLoading(false);
      const response = data.checkDigitPassword;
      if (response.ok) {
        setHasNewPassword(response.hasDigit);
      } else {
        errorMessage(response.message, enqueueSnackbar);
      }
    },
    onError(error) {
      console.error(error);
      setLoading(false);
    },
  });
  useEffect(() => {
    if (!token) {
      console.log('Token is undefined');
    } else {
      const usernameToken = parseJwt(token);
      checkDigitPasswordMutation({
        variables: {
          documentNumber: usernameToken.userName,
        },
      });
      setFormData({
        ...formData,
        username: usernameToken.userName,
      });
    }
  }, []);
  useEffect(() => {
    if (loginData) {
      const {
        tokenAuth: { payload },
      } = loginData;
      if (payload.usergroup === 'admin_group') {
        history.push('/administrador');
      } else if (payload.usergroup === 'client_group') {
        if (user && user.currentUser)
          payload.userFullName = `${user?.currentUser?.firstName} ${user?.currentUser?.lastName}`;
        window.dataLayer.push({ event: 'Login Intranet', info: payload });
        history.push('/cliente');
      }
    }
  }, [loginData, user]);

  const resetPassword = () => {
    setShowRecoverPass(!showRecoverPass);
  };
  useEffect(() => {
    if (formData.username.length > 7) {
      checkDigitPasswordMutation({
        variables: {
          documentNumber: formData.username,
        },
      });
    }
  }, [hasNewPassword, formData.username]);

  const handlerDocument = event => {
    setFormData({
      ...formData,
      username: event.target.value,
    });
  };

  return (
    <>
      <div className={classes.layout}>
        <div className={classes.form}>
          {showRecoverPass ? (
            <SendRecoverMailPage handlerRecoverPass={resetPassword} />
          ) : (
            <>
              {hasNewPassword === false && (
                <div className={classes.mssgDiv}>
                  <p className={classes.mssgText}>
                    Por favor, actualice su contraseña a dígitos
                  </p>
                </div>
              )}
              <TextField
                type="text"
                label="Ingrese su documento"
                name="document"
                variant="outlined"
                value={formData.username}
                autoComplete="off"
                onChange={handlerDocument}
                fullWidth
              />
              {hasNewPassword === false && (
                <NewPasswordFormLogin
                  handlerRecoverPass={resetPassword}
                  documentNumbers={formData.username}
                  handlerChangePassword={() =>
                    setHasNewPassword(!hasNewPassword)
                  }
                />
              )}
              {hasNewPassword && (
                <NumericFormLogin
                  documentNumber={formData.username}
                  handlerRecoverPass={resetPassword}
                  cognito={cognito}
                  pathCognito={pathCognito}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default FormLogin;
