import { makeStyles, createStyles } from '@material-ui/core/styles';

export default makeStyles(theme =>
  createStyles({
    sectionText: {
      paddingTop: 25,
    },
    inputContainer: {
      paddingTop: 15,
      '& p': {
        textAlign: 'center',
      },
    },
    document: {
      display: 'flex',
      flexDirection: 'column',
      margin: '0 30px',
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 800,
    },
    errorTypo: {
      color: 'red',
      textAlign: 'left',
      marginTop: '5px',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px',
      letterSpacing: '0.02em',
    },
    documentIcon: {
      height: 100,
      width: 'auto',
      color: theme.palette.secondary.light,
    },
    downloadIcon: {
      color: theme.palette.secondary.light,
      height: 18,
    },
    downloadButton: {
      height: 30,
      width: 125,
      fontWeight: 'normal',
      borderRadius: 30,
      fontSize: '0.85rem',
      background: theme.palette.primary.main,
      color: 'white',
      textDecoration: 'none',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    table: {
      marginTop: 40,
    },
    tableHead: {
      marginBottom: 30,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      '& h3': {
        fontWeight: 600,
        fontSize: 18,
        textTransform: 'uppercase',
      },
    },
    rowIcon: {
      height: 50,
      width: 'auto',
      color: theme.palette.secondary.light,
    },
    squareBullet: {
      color: theme.palette.secondary.light,
    },
    listTitle: {
      '& .MuiTypography-root': {
        fontWeight: 600,
        lineHeight: 0,
      },
    },
    leftDivider: {
      borderLeftWidth: 2,
      borderLeftColor: theme.palette.secondary.main,
      borderLeftStyle: 'solid',
    },
    infoSection: {
      marginTop: 15,
    },
    icon: {
      fontSize: 350,
    },
    tableTitle: {
      fontWeight: 500,
    },
    container: {
      paddingBottom: 20,
      backgroundColor: theme.palette.colors.white,
    },
  }),
);
